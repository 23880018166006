import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonBackButton, IonCheckbox, IonSelect, IonSelectOption, IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonButton, IonList, IonItem, IonInput, IonText, IonCol, IonBadge } from '@ionic/react';
import { addAddress } from '../data/user/user.actions';
import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import { ICity, IRegion, IUser, IDeliveryCharges } from '../models/User';
import { loadAddresses } from '../data/user/user.actions';
import './Login.scss';

interface OwnProps extends RouteComponentProps { }

interface StateProps {
  token: string,
  user: IUser;
  cities: ICity[];
  regions: IRegion[];
  deliveryCharges: IDeliveryCharges[];
};

interface DispatchProps {
  addAddress: typeof addAddress;
  //loadCities: typeof loadCities;
  loadAddresses: typeof loadAddresses
}

interface AddressProps extends OwnProps, StateProps, DispatchProps { };

const Address: React.FC<AddressProps> = ({ addAddress, loadAddresses, history, token, user, cities, regions, deliveryCharges }) => {

  const [contactName, setContactName] = useState('');
  const [contactNameError, setContactNameError] = useState(false);
  const [contactNameErrorText, setContactNameErrorText] = useState('');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState('');

  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorText, setPhoneErrorText] = useState('');

  const [streetAddress, setStreetAddress] = useState('');
  const [streetAddressError, setStreetAddressError] = useState(false);
  const [streetAddressErrorText, setStreetAddressErrorText] = useState('');

  const [city, setCity] = useState('');
  const [region, setRegion] = useState('');
  const [cityError, setCityError] = useState(false);
  const [cityErrorText, setCityErrorText] = useState('');

  const [isDelivery, setIsDelivery] = useState(false);
  const [isBilling, setIsBilling] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [, setCreateAddressError] = useState(false);
  const [, setCreateAddressErrorText] = useState('');

  useEffect(() => {
    //loadCities(token);
    setContactName((user.name.first + ' ' + user.name.last).trim());
    setEmail(user.email);
    setPhone(user.phone);
  }, []);

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (!contactName) {
      setContactNameError(true);
      setContactNameErrorText("Contact name is required");
    }

    if (!email) {
      setEmailError(true);
      setEmailErrorText("Email is required");
    }

    if (!phone) {
      setPhoneError(true);
      setPhoneErrorText("Phone is required");
    }

    if (!streetAddress) {
      setStreetAddressError(true);
      setStreetAddressErrorText("Street address is required");
    }

    if (!city) {
      setCityError(true);
      setCityErrorText("City is required");
    }

  };

  useEffect(() => {
    if (formSubmitted && !contactNameError && !emailError && !phoneError && !streetAddressError && !cityError) {
      addAddress(contactName, email, phone, streetAddress, city, isDelivery, isBilling, user._id, createAddressCallback, token);
    }
    setFormSubmitted(false);
  }, [formSubmitted, contactNameError, emailError, phoneError, streetAddressError, cityError]);

  const createAddressCallback = useCallback((data: { insertedId: Number | null, message: string }) => {
    if (data.insertedId) {
      loadAddresses(token);
      history.goBack();
      //history.push('/tabs/checkout', { direction: 'none' });
    } else {
      setCreateAddressError(true);
      setCreateAddressErrorText(data.message);
      setFormSubmitted(false);
    }
  }, []);

  return (
    <IonPage id="address-page">
      <IonContent>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton text="" defaultHref="/tabs/home" />

            </IonButtons>
            <IonTitle>Add Address</IonTitle>
          </IonToolbar>
        </IonHeader>

        <form noValidate onSubmit={submit}>
          <IonList className="input-list">
            <IonItem lines="none">
              <IonInput className="input-text-no-top-margin input-text-no-bottom-margin" placeholder='Enter your Contact Name' name="contactname" type="text" value={contactName} spellCheck={false} autocapitalize="off" onIonChange={e => { setContactName(e.detail.value!); setContactNameError(false) }}
                required>
              </IonInput>
            </IonItem>

            {contactNameError && <IonText color="danger">
              <p className="ion-padding-start">
                {contactNameErrorText}
              </p>
            </IonText>}

            <IonItem lines="none">
              <IonInput className="input-text-tight" placeholder="Enter your Email" name="email" type="email" value={email} onIonChange={e => { setEmail(e.detail.value!); setEmailError(false) }}>
              </IonInput>
            </IonItem>

            {emailError && <IonText color="danger">
              <p className="ion-padding-start">
                {emailErrorText}
              </p>
            </IonText>}

            <IonItem lines="none">
              <IonInput className="input-text-tight" placeholder="Enter your Mobile Number" name="phone" type="tel" value={phone} onIonChange={e => { setPhone(e.detail.value!); setPhoneError(false) }}>
              </IonInput>
            </IonItem>

            {phoneError && <IonText color="danger">
              <p className="ion-padding-start">
                {phoneErrorText}
              </p>
            </IonText>}

            <IonItem lines="none">
              <IonInput className="input-text-tight" placeholder="Enter your Delivery Address" name="streetAddress" type="text" value={streetAddress} onIonChange={e => { setStreetAddress(e.detail.value!); setStreetAddressError(false) }}>
              </IonInput>
            </IonItem>

            {streetAddressError && <IonText color="danger">
              <p className="ion-padding-start">
                {streetAddressErrorText}
              </p>
            </IonText>}

            <IonItem lines="none">
              <IonSelect interface="popover"  className="input-select" value={city} placeholder=" Select Your City" onIonChange={
                e => { setCity(e.detail?.value[0]); setRegion(e.detail?.value[1]); setCityError(false); }
              }>
                {cities.map(city => (
                  <IonSelectOption key={city._id} value={[city._id, city.region]}  >
                    {city.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
              {/*
               <IonText color="primary">
                {region &&
                <IonBadge color="primary">
                  <p>Your Delivery Region is {regions.find(val => val._id === region)?.name}</p>
                  <p>Delivery Charge will be LKR {deliveryCharges.find(deliveryCharge => deliveryCharge.region === region)?.deliveryCharges + "/-"} </p>
                  <p>For the Orders below LKR {deliveryCharges.find(deliveryCharge => deliveryCharge.region === region)?.thresholdValue + "/-"}</p>
                </IonBadge>}
                { <p >
                  <b>Your Delivery Region is </b>{regions.find(val => val._id === region)?.name}<br/>
                  <b>Delivery Charge will be </b> LKR {deliveryCharges.find(deliveryCharge => deliveryCharge.region === region)?.deliveryCharges + "/-"} <br/>
                  <b>For the Orders below </b> LKR {deliveryCharges.find(deliveryCharge => deliveryCharge.region === region)?.thresholdValue + "/-"}
                </p> }
              </IonText>
                */}
            </IonItem>
            
            <IonItem lines="none">
            <IonText color="primary">
                {region &&
                <IonBadge color="primary">
                  <p>Your Delivery Region is {regions.find(val => val._id === region)?.name}</p>
                  <p>Delivery Charge will be LKR {deliveryCharges.find(deliveryCharge => deliveryCharge.region === region)?.deliveryCharges + "/-"} </p>
                  <p>For the Orders below LKR {deliveryCharges.find(deliveryCharge => deliveryCharge.region === region)?.thresholdValue + "/-"}</p>
                </IonBadge>}
                {/* <p >
                  <b>Your Delivery Region is </b>{regions.find(val => val._id === region)?.name}<br/>
                  <b>Delivery Charge will be </b> LKR {deliveryCharges.find(deliveryCharge => deliveryCharge.region === region)?.deliveryCharges + "/-"} <br/>
                  <b>For the Orders below </b> LKR {deliveryCharges.find(deliveryCharge => deliveryCharge.region === region)?.thresholdValue + "/-"}
                </p> */}
              </IonText>
            </IonItem>
            {cityError && <IonText color="danger">
              <p className="ion-padding-start">
                {cityErrorText}
              </p>
            </IonText>}

           
            <IonItem lines="none">
              <IonCol className='ion-no-padding' size='11'>
                <p>This is my default Delivery Address</p>
              </IonCol>
              <IonCol>
                <IonCheckbox value="delivery" checked={isDelivery} onIonChange={e => setIsDelivery(e.detail.value!)} />
              </IonCol>
            </IonItem>

            <IonItem lines="none">
              <IonCol className='ion-no-padding' size='11'>
                <p>This is my default Billing Address</p>
              </IonCol>
              <IonCol>
                <IonCheckbox value="delivery" checked={isBilling} onIonChange={e => setIsBilling(e.detail.value!)} />
              </IonCol>
            </IonItem>

            <IonItem lines="none">
              <IonButton type="submit" expand="block" className="save-button">Save</IonButton>
            </IonItem>

          </IonList>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    token: selectors.getToken(state, OwnProps),
    user: state.user?.user[0],
    cities: selectors.getCities(state, OwnProps),
    regions: selectors.getRegions(state, OwnProps),
    addresses: state.user?.addresses,
    deliveryCharges: selectors.getDeliveryCharges(state)
  }),
  mapDispatchToProps: {
    addAddress,
    //loadCities,
    loadAddresses
  },
  component: Address
})
