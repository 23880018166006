import { createSelector } from 'reselect';
import { Schedule, Session, ScheduleGroup } from '../models/Schedule';
import { Speaker } from '../models/Speaker';
import { Location } from '../models/Location';
import { AppState } from './state';
import { IMainMenu, IBrand, ICategoryItems, IItem } from '../models/Item';
import { ICity, INotification, IOrder } from '../models/User';

const getSchedule = (state: AppState) => {

  return state.data.schedule
};
export const getUserDetails = (state: AppState) => {
  if(state.user.user)
    return state.user.user[0]
  else 
    return [];
  };
export const getMainMenus = (state: AppState) => state.item.mainMenus;
export const getBrands = (state: AppState) => state.item.brands;
export const getCategories = (state: AppState) => state.item.categories;
export const getCategoryItems = (state: AppState) => state.item.categoryItems;
export const getItems = (state: AppState) => state.item.items;
export const getCart = (state: AppState) => state.item.cart;
export const getAddresses = (state: AppState) => state.user.addresses;
export const getPaymentMethods = (state: AppState) => state.user.paymentMethods;
export const getDeliveryCharges = (state: AppState) => state.user.deliveryCharges;
const getSessions = (state: AppState) => state.data.sessions;
const getFilteredTracks = (state: AppState) => state.data.filteredTracks;
const getFavoriteIds = (state: AppState) => state.data.favorites;
const getSearchText = (state: AppState) => state.data.searchText;

export const getFilteredSchedule = createSelector(
  getSchedule, getFilteredTracks,
  (schedule, filteredTracks) => {
    const groups: ScheduleGroup[] = [];
    schedule.groups.forEach((group: ScheduleGroup) => {
      const sessions: Session[] = [];
      group.sessions.forEach(session => {
        session.tracks.forEach(track => {
          if (filteredTracks.indexOf(track) > -1) {
            sessions.push(session);
          }
        })
      })
      if (sessions.length) {
        const groupToAdd: ScheduleGroup = {
          time: group.time,
          sessions
        }
        groups.push(groupToAdd);
      }
    });

    return {
      date: schedule.date,
      groups
    } as Schedule;
  }
);

export const getSearchedSchedule = createSelector(
  getFilteredSchedule, getSearchText,
  (schedule, searchText) => {
    if (!searchText) {
      return schedule;
    }
    const groups: ScheduleGroup[] = [];
    schedule.groups.forEach(group => {

      const sessions = group.sessions.filter(s => s.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
      if (sessions.length) {
        const groupToAdd: ScheduleGroup = {
          time: group.time,
          sessions
        }
        groups.push(groupToAdd);
      }
    });
    return {
      date: schedule.date,
      groups
    } as Schedule;
  }
)

export const getScheduleList = createSelector(
  getSearchedSchedule,
  (schedule) => schedule
);

export const getGroupedFavorites = createSelector(
  getScheduleList, getFavoriteIds,
  (schedule, favoriteIds) => {
    const groups: ScheduleGroup[] = [];
    schedule.groups.forEach(group => {
      const sessions = group.sessions.filter(s => favoriteIds.indexOf(s.id) > -1)
      if (sessions.length) {
        const groupToAdd: ScheduleGroup = {
          time: group.time,
          sessions
        }
        groups.push(groupToAdd);
      }
    });
    return {
      date: schedule.date,
      groups
    } as Schedule;
  }
);


const getIdParam = (_state: AppState, props: any) => {
  return props.match.params['id'];
}
const get_IdParam = (_state: AppState, props: any) => {
  return props.match.params['_id'];
}

export const getSession = createSelector(
  getSessions, getIdParam,
  (sessions, id) => {
    return sessions.find((s: Session) => s.id === id);
  }
);

/*export const getSpeaker = createSelector(
  getSpeakers, getIdParam,
  (speakers, id) => speakers.find((x: Speaker) => x.id === id)
);*/

/*export const getMainMenu = createSelector(
  getMainMenus, get_IdParam,
  (mainmenus, id) => mainmenus.find((x: IMainMenu) => x._id === id)
);

export const getBrand = createSelector(
  getBrands, get_IdParam,
  (brands, id) => brands.find((x: IBrand) => x._id === id)
);

export const getItem = createSelector(
  getItems, get_IdParam,
  (items, id) => items.find((x: IItem) => x._id === id)
);*/

export const getSpeakerSessions = createSelector(
  getSessions,
  (sessions) => {
    const speakerSessions: { [key: string]: Session[] } = {};

    sessions.forEach((session: Session) => {
      session.speakerNames && session.speakerNames.forEach(name => {
        if (speakerSessions[name]) {
          speakerSessions[name].push(session);
        } else {
          speakerSessions[name] = [session];
        }
      })
    });
    return speakerSessions;
  }
);

export const mapCenter = (state: AppState) => {
  const item = state.data.locations.find((l: Location) => l.id === state.data.mapCenterId);
  if (item == null) {
    return {
      id: 1,
      name: 'Map Center',
      lat: 43.071584,
      lng: -89.380120
    };
  }
  return item;
}

export const getItem = createSelector(
  getItems, getIdParam,
  (items, id) => items.find((x: IItem) => x._id === id)
);

export const getUser = (state: AppState, props: any) => {
  return state.user
};

export const getToken = (state: AppState, props: any) => {
  return state.user.token
};

export const getTermsAndConditions = (state: AppState, props: any) => {
  const termsAndConditions = state.data.notifications.find((notification: INotification) => notification.name === "TERMS_AND_CONDITIONS");
  return termsAndConditions;
};

export const getFAQ = (state: AppState, props: any) => {
  const faq = state.data.notifications.find((notification: INotification) => notification.name === "FAQ");
  return faq;
};

export const getAboutUs = (state: AppState, props: any) => {
  const aboutUs = state.data.notifications.find((notification: INotification) => notification.name === "ABOUTUS_INTRODUCTION");
  return aboutUs;
};

export const getMinOrderValue = (state: AppState, props: any) => {
  const {value} = state.data.notifications.find((notification: INotification) => notification.name === "MIN_ORDER_QTY");
  return value;
};

export const getSameDayDeliveryMsg = (state: AppState, props: any) => {
  const sameDayDeliveryMsg = state.data.notifications.find((notification: INotification) => notification.name === "FAST_DELIVERY");
  return sameDayDeliveryMsg;                                                                                         
};

export const getCities = (state: AppState, props: any) => {
  const cities = state.data.cities //as ICity
  return cities;
};

export const getRegions = (state: AppState, props: any) => {
  const regions = state.data.regions
  return regions;
};

export const getOrders = (state: AppState) => {
  const orders = state.user.orders as IOrder[]
  return orders;
};