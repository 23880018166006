import { ItemActions } from './item.actions';
import { IItemState } from './item.state';

export const itemReducer = (state: IItemState, action: ItemActions): IItemState => {
  switch (action.type) {
    
    case 'set-item-loading': {
      return { ...state, loading: action.isLoading };
    }

    case 'set-item-data': {
      return { ...state, ...action.data };
    }

    case 'set-price-changed-items-data': {
      return { ...state, items: action.items};
    }

    case 'set-cart-data': {
      return  { ...state , cart:action.cart};
    }
    
    case 'set-item-currentMenu': {
      return  { ...state , currentMenu:action.currentMenu};
    }
    
  }
}