import React from 'react';
import { RouteComponentProps, withRouter, useLocation } from 'react-router';
import { IonIcon, IonItem, IonLabel, IonMenu, IonMenuToggle } from '@ionic/react';
import { mailOutline, help, informationCircleOutline, logIn, logOut, keyOutline, home, person, personAdd, receiptOutline, helpCircle, cart, moonOutline, } from 'ionicons/icons';

import { connect } from '../../data/connect';
import { setDarkMode } from '../../data/user/user.actions';
import { MenuContent } from './MenuContent';
import './Menu.css'

const routes = {
  appPages: [
    { title: 'Home', path: '/tabs/homeorintro', icon: home },
    { title: 'About Us', path: '/tabs/about', icon: informationCircleOutline }
    // { title: 'FAQ', path: '/tabs/faq', icon: helpCircle },
    // { title: 'Support', path: '/tabs/support', icon: help },
  ],
  loggedInPages: [
    { title: 'Shopping Cart', path: '/tabs/shoppingcart', icon: cart },
    { title: 'My Orders', path: '/tabs/myOrders', icon: receiptOutline }
  ],
  loggedInAccountPages: [
    { title: 'My Account', path: '/account', icon: person },
    { title: 'Address Book', path: '/addressBook', icon: mailOutline },
    { title: 'Logout', path: '/logout', icon: logOut }
  ],
  loggedOutPages: [
    { title: 'Login', path: '/login', icon: logIn },
    { title: 'Forgot Password', path: '/forgotPassword', icon: keyOutline },
    { title: 'Signup', path: '/signup', icon: personAdd }
  ]
};

interface Pages {
  title: string,
  path: string,
  icon: string,
  routerDirection?: string
}
interface StateProps {
  darkMode: boolean;
  isAuthenticated: boolean;
  menuEnabled: boolean;
}

interface DispatchProps {
  setDarkMode: typeof setDarkMode
}

interface MenuProps extends RouteComponentProps, StateProps, DispatchProps { }

const Menu: React.FC<MenuProps> = ({ darkMode, history, isAuthenticated, setDarkMode, menuEnabled }) => {
  const location = useLocation();

  function renderlistItems(list: Pages[]) {
    return list
      .filter(route => !!route.path)
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem detail={false} routerLink={p.path} routerDirection="none" className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu type="overlay" disabled={!menuEnabled} contentId="main">
      <MenuContent>
        <MenuContent.List title='Gusta'>
          {renderlistItems(routes.appPages)}
          {console.log("+-+-+", isAuthenticated, )}
          {renderlistItems(isAuthenticated ? routes.loggedInPages : /*routes.loggedOutPages*/[])}
        </MenuContent.List>
        <MenuContent.List title='Account'>
          {renderlistItems(isAuthenticated ? routes.loggedInAccountPages : routes.loggedOutPages)}
          {/* <MenuContent.Item
            icon={moonOutline}
            label='Dark Mode'
            isChecked={darkMode}
            onClick={() => setDarkMode(!darkMode)} /> */}
        </MenuContent.List>
      </MenuContent>
    </IonMenu>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isLoggedin ? state.user.isLoggedin : false,
    menuEnabled: state.data.menuEnabled
  }),
  mapDispatchToProps: ({
    setDarkMode
  }),
  // component: withRouter(Menu)
  component: Menu
})
