/**
 * @file Rendering MainMenu, Brands, categories, Fast Deliveries, Promotions and Items
 * @author J. Nalinda
*/
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonIcon, IonButton, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonSearchbar, IonBadge, useIonViewDidEnter, useIonLoading, IonCard, IonCardHeader, IonLabel, IonItemDivider, IonItem, IonImg, IonText, IonAlert, IonToast } from '@ionic/react';
import { arrowBack, cartSharp, bicycle } from 'ionicons/icons';
import concat from 'lodash/concat';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { addToCart, deleteCart, getItemPrice, getPriceChangedItems, updateCart, addCurrentMenu } from '../data/items/item.actions';

import { IMainMenu, IBrand, ICategoryItems, IItem, ICategoryChildren, ICategory, ICart, IList, Type } from '../models/Item';
import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import { useGreeting } from '../util/hooks';
import './Item.scss';
import { Redirect, RouteComponentProps } from 'react-router';
import { type } from 'os';
import { INotification } from '../models/User';


interface IOwnProps extends RouteComponentProps { 
  token: string;
} 

interface IStateProps {
  mainMenu: IMainMenu[];
  brand: IBrand[];
  category: ICategory[];
  categoryItems: ICategoryItems[];
  item: IItem[];
  cart: ICart[];
  isLoggedin: boolean;
  firstName: string;
  sameDayDeliveryMsg: INotification;  
  currentMenu:number;
};

interface IDispatchProps {
  addToCart?: typeof addToCart;
  updateCart?: typeof updateCart;
  getItemPrice?: typeof getItemPrice;
  getPriceChangedItems?: typeof getPriceChangedItems;
  deleteCart?: typeof deleteCart;
  addCurrentMenu?: typeof addCurrentMenu;
};


//interface IItemProps extends IOwnProps, IStateProps, IDispatchProps { };
type IItemProps = IOwnProps & IStateProps & IDispatchProps;

const Item: React.FC<IItemProps> = ({ mainMenu, brand, category, categoryItems, item, cart, isLoggedin, firstName, sameDayDeliveryMsg, currentMenu, addToCart, updateCart, getItemPrice, getPriceChangedItems, deleteCart , addCurrentMenu, token, history }) => {

  const [isLoading, setIsLoading] = useState(true);
  const [isAddToCart, setIsAddToCart] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showAddedtoCart, setShowAddedtoCart] = useState(false);
  const [showSameDayDelivery, setShowSameDayDelivery] = useState(false);
  const [clickedMenu, setClickedMenu] = useState(0);
  const [prevMenu, setPrevMenu] = useState(0);
  const [menuCount, setMenuCount] = useState(0);
  const [clickedRef, setClickedRef] = useState('');
  const [prevRef, setPrevRef] = useState('');
  const [searchText, setSearchText] = useState('');
  const [backClicked, setBackClicked] = useState(false);
  const [hierarchy, setHierarchy] = useState<any[]>([]);
  const [historyList, setHistoryList] = useState<IList[]>([]);
  const [historyHierarchy, setHistoryHierarchy] = useState<IList[]>([]);
  const [list, setList] = useState<IList[]>([]);
  const [cartItem, setCartItem] = useState<IList>();
  const [searched, setSearched] = useState(false);
  const [searchBack, setSearchBack] = useState(false);
  const [cartQty, setCartQty] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [isDetails] = useState(false);
  const [present, dismiss] = useIonLoading();
  const [itemPrice, setItemPrice] = useState(0);
  const itemPriceRef = useRef(0);
  
  const greeting = useGreeting(firstName,isLoggedin);
    
  var categoryIds: string[] = [];
  var itemIds: string[] = [];
  var selectedCategoryItems: any[] = [];
  var historyCategoryItems: any[] = [];
  var historyListItems: any[] = [];


  if (Capacitor.isNative) {
    App.addListener('backButton', ({ canGoBack }) => {
      if (!canGoBack /*|| location.pathname === '/tabs/home'*/) {
        App.exitApp();
      } else {
        goBack();
        console.log('Handler was called!');
      }
    });
  }

  App.addListener('backButton', (ev: any) => {
    ev.detail.register(10, () => {
      goBack();
      console.log('Handler was called!');
    });
  });

useEffect(() => {
    if(!isLoading){
      setClickedMenu(0);
      setClickedRef('');
      setQuantity(1);
      if (cart !== undefined && cart.length > 0)
      setCartQty(cart.length);
    }   
},[isLoading]);

useEffect(() => {
  if(!isLoggedin){
    setClickedMenu(0);
    setIsLoading(false);
  }else if(isLoggedin){
    if(currentMenu != 0){
      setClickedMenu(currentMenu);
      setQuantity(1);
      if (cart !== undefined && cart.length > 0){
        setCartQty(cart.length);
      }
    }else{
      setClickedMenu(0);
      setIsLoading(false);
    }
  }
},[isLoggedin]);

useIonViewDidEnter(() => {
  
    // if((currentMenu == 999 || currentMenu == 0) && !isLoading){
    //   setClickedMenu(0);
    // }
    present({
      message: 'Loading...',
      duration: 2000,
      spinner: 'circles'
    })
    setIsLoading(!isLoading);
  });

  useEffect(() => {
    if(currentMenu == 0){
      setClickedMenu(0);
    }
 }, [currentMenu])

  useEffect(() => {
    let searchBrandList: any[] = [];
    let searchItemList: any[] = [];
    let searchList: any[] = [];
    if (searchText.trim() !== '') {
      Promise.all([
        searchBrandList = brand.filter(obj => obj.name.toLowerCase().includes(searchText.toLowerCase())),
        searchBrandList = searchBrandList.map(obj => { return { ...obj, type: 'B' } }),

        searchItemList = item.filter(obj => obj.name.toLowerCase().includes(searchText.toLowerCase())),
        searchItemList = searchItemList.map(obj => {
          return {
            _id: obj._id,
            name: obj.name,
            desc: obj.desc,
            image: {
              url: obj.images && obj.images[0] ? obj.images[0].url : "",
            },
            sortOrder: obj.sortOrder,
            type: 'I',
            save: obj.originalPrice - obj.price
          }
        }),
        searchList = concat(searchBrandList, searchItemList),
        setList(searchList),
        setSearched(true),
        /*setClickedMenu(100),*/
        /*Fix serach issue*/
        setClickedMenu(901),
        setClickedRef(searchItemList.length>0?searchItemList[0]._id:""),
      ]);
    }
    else {
      setList(mainMenu.map(obj => { return { ...obj }}));
      setSearched(false);
      setClickedMenu(0);
      setClickedRef('');
    }
  }, [searchText,searchBack])

  useEffect(() => {
    console.log("clickedMenu "+ clickedMenu);
    addCurrentMenu!(clickedMenu);
    setQuantity(1);
    switch (clickedMenu) {
      case 1:
        setList(brand.map(obj => { return { ...obj }}));
        break;

      case 2:
        categoryItems.map(ele => {
          if (ele.hasOwnProperty('category') && ele.category.length > 0) {
            ele.category.map(val => {
              if (categoryIds.indexOf(val._id) === -1)
                categoryIds.push(val._id);
              selectedCategoryItems.push({ ...val, item: ele.item });
            })
          }
        })
        setHierarchy(selectedCategoryItems);
        const filterCategory = category.filter(obj => categoryIds.indexOf(obj._id) !== -1)
        setList(filterCategory.map(obj => { return { ...obj }}));
        break;

      case 200:
        setPrevMenu(clickedMenu);
        setPrevRef(clickedRef);
        findHierarchy(clickedRef);
        break;

      case 3:
        setShowSameDayDelivery(true);
        setPrevMenu(clickedMenu);
        const filterFastDelivery = item.filter(obj => obj.isFastDelivery).sort(obj => obj.sortOrder)
        setList(filterFastDelivery.map(obj => {
          return {
            _id: obj._id,
            name: obj.name,
            desc: obj.desc,
            mobileDescription: obj.mobileDescription,
            purchasePrice: obj.purchasePrice,
            originalPrice: obj.originalPrice,
            price: obj.discountPercentage > 0 || obj.originalPrice < obj.price ? obj.price : obj.price + (obj.originalPrice - obj.price)/2,
            discountPercentage: obj.discountPercentage,
            priceUnit: obj.priceUnit,
            duration: obj.duration,
            durationUnit: obj.durationUnit,
            isPromotion : obj.isPromotion,
            isFastDelivery: obj.isFastDelivery,
            image: {
              url: obj.images && obj.images[0] ? obj.images[0].url : "",
            },
            brand: obj.brand,
            sortOrder: obj.sortOrder,
            type: 'I',
            save: obj.discountPercentage > 0 ? obj.originalPrice - obj.price : (obj.originalPrice - obj.price)/2
          }
        }));
        break;

      case 4:
        setPrevMenu(clickedMenu);
        const filterPromotion = item.filter(obj => obj.isPromotion)
        setList(filterPromotion.map(obj => {
          return {
            _id: obj._id,
            name: obj.name,
            desc: obj.desc,
            mobileDescription: obj.mobileDescription,
            purchasePrice: obj.purchasePrice,
            originalPrice: obj.originalPrice,
            price: obj.discountPercentage > 0 || obj.originalPrice < obj.price ? obj.price : obj.price + (obj.originalPrice - obj.price)/2,
            discountPercentage: obj.discountPercentage,
            priceUnit: obj.priceUnit,
            duration: obj.duration,
            durationUnit: obj.durationUnit,
            isPromotion : obj.isPromotion,
            isFastDelivery: obj.isFastDelivery,
            image: {
              url: obj.images && obj.images[0] ? obj.images[0].url : "",
            },
            sortOrder: obj.sortOrder,
            brand: obj.brand,
            type: 'I',
            save: obj.discountPercentage > 0 ? obj.originalPrice - obj.price : (obj.originalPrice - obj.price)/2
          }
        }));
        break;

      case 100:
      case 800:
      case 900:
        setPrevMenu(clickedMenu);
        setPrevRef(clickedRef);
        const filterItem = item.filter(obj => obj.brand === clickedRef)
        setList(filterItem.map(obj => {
          return {
            _id: obj._id,
            name: obj.name,
            desc: obj.desc,
            mobileDescription: obj.mobileDescription,
            purchasePrice: obj.purchasePrice,
            originalPrice: obj.originalPrice,
            price: obj.discountPercentage > 0 || obj.originalPrice < obj.price ? obj.price : obj.price + (obj.originalPrice - obj.price)/2,
            discountPercentage: obj.discountPercentage,
            priceUnit: obj.priceUnit,
            duration: obj.duration,
            durationUnit: obj.durationUnit,
            isPromotion : obj.isPromotion,
            isFastDelivery: obj.isFastDelivery,
            image: {
              url: obj.images && obj.images[0] ? obj.images[0].url : "",
            },
            sortOrder: obj.sortOrder,
            brand: obj.brand,
            type: 'I',
            save: obj.discountPercentage > 0 ? obj.originalPrice - obj.price : (obj.originalPrice - obj.price)/2
          }
        }));
        searched && backClicked && setSearchBack(!searchBack);
        break;
      
      case 201:  
      case 901: // From Hierachy
        if (backClicked) {
          const historyHierarchyItems: any = historyHierarchy.pop();
          const historyListItems: any = historyList.pop();
          setHierarchy(historyHierarchyItems);
          setList(historyListItems);
          menuCount === 0 || historyListItems.length === 0 ? setClickedMenu(2) : setClickedMenu(200);
          setHistoryHierarchy(historyHierarchy);
          setHistoryList(historyList);
        }
        else
          findItem();
        break;

      case 902: // From Other Menus
        findItem();
        break;
    
      default:
        setList(mainMenu.map(obj => { return { ...obj }}));
        break;
    }
  }, [mainMenu, clickedMenu, clickedRef, backClicked, isDetails])

  const findItem = async () => {
    const displayItem = item.filter(obj => obj._id === clickedRef);
    //Fix null error
    if(displayItem.length>0){
      getItemPrice!(clickedRef, displayItem[0].price, displayItem[0].discountPercentage, token, priceCallback);

      setList(displayItem.map(obj => {
        return {
          _id: obj._id,
          name: obj.name, 
          desc: obj.desc,
          mobileDescription: obj.mobileDescription,
          purchasePrice: obj.purchasePrice,
          originalPrice: obj.originalPrice,
          price: obj.discountPercentage > 0 || obj.originalPrice < obj.price ? obj.price : Number(obj.price) + (obj.originalPrice - Number(obj.price))/2,
          discountPercentage: obj.discountPercentage,
          priceUnit: obj.priceUnit,
          duration: obj.duration,
          isPromotion : obj.isPromotion,
          isFastDelivery:obj.isFastDelivery,
          durationUnit: obj.durationUnit,
          image: {
            url: obj.images && obj.images[0] ? obj.images[0].url : "",
          },
          sortOrder: obj.sortOrder,
          brand: obj.brand,
          type: 'I',
          save: obj.discountPercentage > 0 ? obj.originalPrice - obj.price : (obj.originalPrice - Number(obj.price))/2
        }
      }));
    }
  }

  const findMenu = useCallback((clickedMenu: number) => {
    setClickedMenu(clickedMenu);
  }, [clickedMenu]);

  const findRef = useCallback((clickedRef: string, type: string) => {
    setClickedRef(clickedRef);
    setBackClicked(false);
    if (clickedMenu > 0) {
      clickedMenu === 1 && setClickedMenu(100);
      clickedMenu === 2 && setClickedMenu(200);
      (clickedMenu === 3 || clickedMenu === 4 || clickedMenu === 100) && setClickedMenu(902);
      type === Type.Item && clickedMenu === 200 && setClickedMenu(901);
      //type === Type.Item && clickedMenu === 900 && setClickedMenu(901);
      setMenuCount(menuCount + 1);
    }
    type === Type.Brand && setClickedMenu(100);
  }, [clickedMenu, clickedRef, getItemPrice]);

  const goBack = () => {
    if (clickedMenu === 100)
      setSearchText('');
    if ((clickedMenu === 200 || clickedMenu === 201) && historyHierarchy.length > 0) {
      setBackClicked(true);
      setClickedMenu(201);
      setMenuCount(menuCount - 1);
    }
    else if (clickedMenu === 902){
      setClickedMenu(prevMenu);
      setClickedRef(prevRef);
      setBackClicked(true);
      searched && setSearchBack(!searchBack);
    }
    else if (clickedMenu === 901){
      setBackClicked(true);
    }
    else
      setClickedMenu(clickedMenu > 99 ? clickedMenu / 100 : clickedMenu - clickedMenu);
  };

  const findHierarchy = useCallback((clickedRef: string) => {
    if (!backClicked) {

      const clickedCategoryItems: ICategoryChildren[] = hierarchy.filter(obj => obj._id == clickedRef);

      clickedCategoryItems.map(obj => {
        if (obj.hasOwnProperty('children') && obj.children.length > 0) {
          obj.children.map(val => {
            if (val.hasOwnProperty('_id')) {
              if (categoryIds.indexOf(val._id) === -1) {
                categoryIds.push(val._id);
                selectedCategoryItems.push({ ...val, item: obj.item });
              }
            }
          })
        }
        else {
          if (itemIds.indexOf(obj.item) === -1)
            itemIds.push(obj.item);
        }
      })

      setHierarchy(selectedCategoryItems);
      
      const filterCategory = category.filter(obj => categoryIds.indexOf(obj._id) !== -1)
      const filterItem = item.filter(obj => itemIds.indexOf(obj._id) !== -1)
      const filterList = (concat(filterItem.map(obj => {
        return {
          _id: obj._id,
          name: obj.name,
          desc: obj.desc,
          mobileDescription: obj.mobileDescription,
          purchasePrice: obj.purchasePrice,
          originalPrice: obj.originalPrice,
          price: obj.discountPercentage > 0 || obj.originalPrice < obj.price ? obj.price : Number(obj.price) + (obj.originalPrice - Number(obj.price))/2,
          discountPercentage: obj.discountPercentage,
          priceUnit: obj.priceUnit,
          duration: obj.duration,
          durationUnit: obj.durationUnit,
          isPromotion : obj.isPromotion,
          isFastDelivery:obj.isFastDelivery,
          image: {
            url: obj.images && obj.images[0] ? obj.images[0].url : "",
          },
          sortOrder: obj.sortOrder,
          brand: obj.brand,
          type: 'I',
          save: obj.discountPercentage > 0 ? obj.originalPrice - obj.price : (obj.originalPrice - Number(obj.price))/2
        }
      }),
        filterCategory.map(obj => { return { ...obj /*,  type: 'C'*/
                                                      /*mobileDescription: '',
                                                      purchasePrice: 0,
                                                      originalPrice: 0,
                                                      price: 0,
                                                      discountPercentage: 0,
                                                      priceUnit: '',
                                                      duration: 0,
                                                      durationUnit: '',
                                                      isPromotion: false,
                                                      save: 0*/ 
        }})
      )
      );

      setList(filterList);
      historyCategoryItems = historyHierarchy;
      historyListItems = historyList;
      if (hierarchy.length > 0 && clickedMenu > 2) {
        historyCategoryItems.push(hierarchy);  // to go back 
        historyListItems.push(filterList);
      }
      setHistoryHierarchy(historyCategoryItems);
      setHistoryList(historyListItems);
    }
  }, [clickedMenu, clickedRef]);

  const updateQuantity = (qty: any) => {
    if (qty > 0) {
      setQuantity(qty);
    }
  }

  useEffect(() => {
    if(cartItem != undefined && cartItem._id != '' ) {
      getItemPrice!(clickedRef, cartItem.discountPercentage > 0 || cartItem.originalPrice < cartItem.price ? cartItem.price:(cartItem.price-cartItem.save), cartItem.discountPercentage, token, addToCartCallback);
      addToCartFunction(cartItem);
    }
  }, [cartItem]);

  const priceCallback = useCallback(async ( data: any ) => {
    const { status } = data;
    await status && getPriceChangedItems!(token);
    await status && setIsLoading(true);
    await status && setShowToast(true);
  }, []);

  const addToCartCallback = useCallback(async ( data: any ) => {
    const { status, price } = data;
    //await status ? itemPriceRef.current = price : itemPriceRef.current = 0;
    await status && setShowAlert(true);
    await status && getPriceChangedItems!(token);
    await status && setIsLoading(true);

  }, []);


  const addToCartFunction = async (list:IList) => {
    //if(isAddToCart)
    //  return;
    if (!isLoggedin) {
      history.push('/login', { direction: 'none' }); // redirecting if not logged in
      return;
    }
    let price: number = 0, discount: number = 0, isExisting: boolean = false, itemQty: number = 0, isCartAvail: boolean = false, _id: string = '';

    if (cart !== undefined && cart.length > 0) {
      isCartAvail = true;
      const cartItem = cart.find(cartItem => list._id === cartItem.orderitems.item);
      if (cartItem) {
        _id = cartItem.orderitems._id;
        isExisting = true;
        itemQty = cartItem.orderitems.quantity;
      }
    }

    itemQty += quantity;
    price = list.price * itemQty;
    discount = list.discountPercentage > 0 ? list.discountPercentage : 0;//list.originalPrice > list.price ? Number(Number((list.originalPrice - list.price) * 100 / list.originalPrice).toFixed(2)):0 ;

    if (!isExisting) {
      let formData = {
        data: {
          item: list._id,
          quantity: itemQty,
          name: list.desc,
          price,
          unitPrice: list.discountPercentage > 0 ? list.originalPrice: list.price,
          purchasePrice: list.purchasePrice,
          originalPrice: list.originalPrice,
          discount,
          isFastDelivery: list.isFastDelivery,
          isCartAvail
        }
      }
      const response = await addToCart!(formData, token);
      setCartQty(cart.length);
    }
    else {
      let formData = {
        data: {
          _id,
          quantity: itemQty,
          name: list.desc,
          price,
          unitPrice: list.discountPercentage > 0 ? list.originalPrice: list.price,
          purchasePrice: list.purchasePrice,
          originalPrice: list.originalPrice,
          discount,
          isFastDelivery: list.isFastDelivery,
        }
      }
      updateCart!(formData, token);
    }
    updateQuantity(1);
  }

  const componentSwitch = (clickedMenu: number, clickedRef: string, list: IList) => {
    if (list!.type === Type.Item /*&& clickedRef !== ''*/ && (/*clickedMenu === 900 ||*/ clickedMenu === 901 || clickedMenu === 902 /*|| (searched && clickedMenu === 900)*/))
      return(
        <div>
          <div className="item-background">
            {
              list?.image !== undefined && list?.image.url.length > 0
              ? <img key={list?.image.url} src={list?.image.url}  />
              : <img src="assets/img/no-image-background.png" alt="Item image" />
            }
            <IonButton className='top-button' onClick={() => {
                                                            setCartItem(list);
                                                            setShowAddedtoCart(true);
                                                          }} > <IonIcon icon={cartSharp}></IonIcon> <IonLabel className="cart">Add to Cart</IonLabel></IonButton>
            
          </div>
          <IonGrid className="ion-padding item-detail">
            <IonRow>
              <IonCol><p><b>{list.desc}</b></p></IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="text"><p>{list.mobileDescription}</p></IonCol>
            </IonRow>
            <IonRow><IonCol></IonCol></IonRow>
            <IonRow>
              <IonCol className='ion-no-padding'>
              <IonButton onClick={() => updateQuantity(quantity - 1)} color="white"><IonLabel className="text">-</IonLabel></IonButton>
              <IonLabel className="count">{quantity}</IonLabel>
              <IonButton onClick={() => updateQuantity(quantity + 1)} color="white"><IonLabel className="text">+</IonLabel></IonButton>
            </IonCol>
            <IonCol>
              <IonButton className='add-to-cart' onClick={() => {
                                                            setCartItem(list);
                                                            setShowAddedtoCart(true);
                                                          }} > <IonIcon icon={cartSharp}></IonIcon> <IonLabel className="cart">Add to Cart</IonLabel></IonButton>
            </IonCol> 
          </IonRow>

          <IonItemDivider></IonItemDivider>

          <IonRow>
            <IonCol size="3"><IonLabel>Price  </IonLabel></IonCol>
            <IonCol size="7"><IonLabel className="text">{list.priceUnit} {list.isPromotion ? <><IonText className='strike-through'> {list.originalPrice.toLocaleString('en-US',{ minimumFractionDigits: 2})} </IonText> <IonText> &nbsp;🠪 </IonText></> : ""} {Number(list. price).toLocaleString('en-US',{ minimumFractionDigits: 2})}</IonLabel></IonCol>
            <IonCol></IonCol><IonCol></IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="3"><IonLabel>Expiry </IonLabel></IonCol>
            <IonCol size="7"><IonLabel class="text">{list.duration} {list.durationUnit}</IonLabel></IonCol>
            <IonCol></IonCol>
            <IonCol></IonCol>
          </IonRow>
        </IonGrid>
      </div>
      )

    return <IonItem button detail={false} lines="none" className="item-item" onClick={() => [findMenu((clickedMenu === 200 || clickedMenu === 201 || clickedMenu === 100) ? 200 : list!.sortOrder), findRef!(list!._id, list!.type)]} /*routerLink={`/tabs/home/${mainMenu._id}`}*/>
      <p className='item-card-p'>
        {
          list!.type === Type.Item && list!.save > 0 &&
          <IonBadge slot="start" className='item-badge'>SAVE : {list!.save.toFixed(0) + '/-'} </IonBadge>
        }

        {
          list!.type === Type.Item &&  list!.isFastDelivery &&
          <IonBadge slot="start" className='fast-badge'><IonIcon icon={bicycle}></IonIcon> </IonBadge>
        }

        {list!.image !== undefined && list!.image.url !== undefined && list!.image.url !== ''
          ? <IonImg src={list!.image.url} alt="item image" />
          : <IonImg src="assets/img/no-image.png" alt="item image" />
        }
        <h2>{list!.name}</h2>
        {list!.type === Type.MainMenu && <p>{list!.desc}</p>}
      </p>
    </IonItem>
  }

  return (
    <IonPage id="item">
      <IonContent >

        <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            cssClass='my-custom-class'
            header={'Price Changed !!'}
            message={`Gusta has just updated item prices and your cart will be erased. Please add your favourite items to cart again. Sorry for the inconvenience.`}
            buttons={[
              {
                text: 'Ok',
                handler: () => {
                  deleteCart!("priceChanged", 0, token);
                  //setIsLoading(true);
                }
              }
            ]}
        />
        <IonToast
          isOpen={showToast}
          duration={3000}
          message="Refreshing.... Gusta has just updated item prices !"
          onDidDismiss={() => setShowToast(false)} 
        />
        <IonToast
          isOpen={showAddedtoCart}
          duration={3000}
          cssClass='changeToast'
          icon='cartSharp'
          message="Added to Cart...!"
          onDidDismiss={() => setShowAddedtoCart(false)} 
        />
        <IonToast
          isOpen={showSameDayDelivery}
          duration={9000}
          layout='stacked'  
          cssClass='changeToast'
          icon='cartSharp'
          message= {sameDayDeliveryMsg && String(sameDayDeliveryMsg.value!.split('\\n'))}
          onDidDismiss={() => setShowSameDayDelivery(false)} 
        />
        <IonHeader translucent={true} >
          <IonToolbar>
            <IonButtons >
              <IonMenuButton />

              {clickedMenu > 0 ?
                <IonButton fill="clear" onClick={goBack}>
                  <IonIcon icon={arrowBack} />
                </IonButton>
                : null
              }
              <IonTitle> {greeting} </IonTitle>
              <IonButtons slot="end">
                {isLoggedin &&
                  <>
                    <IonButton routerLink="/tabs/shoppingcart" >
                      <IonIcon icon={cartSharp}></IonIcon>
                    </IonButton>
                    <IonBadge slot="start">{cart !== undefined && isLoggedin && cart.length > 0 ? cart.length : ""}</IonBadge>
                  </>
                }
              </IonButtons>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonGrid fixed>
          <IonSearchbar value={searchText} animated={true} autocomplete="on" placeholder="Search what you want" onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)} onIonCancel={() => setClickedMenu(0)}> </IonSearchbar>
          <IonRow>
            {list && list.map(list => (
              <IonCol size={list.type === Type.Item && clickedMenu > 900 ?  "12" : "6"} 
                      size-md={list.type === Type.Item && clickedMenu > 900 ?  "12" : "6"} 
                      key={list._id}>
                <IonCard className="item-card">
                  <IonCardHeader>
                    {componentSwitch(clickedMenu, clickedRef!, list)}
                  </IonCardHeader>
                </IonCard>
              </IonCol>
            ))
            }
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default connect<IOwnProps, IStateProps, IDispatchProps>({
  mapStateToProps: (state, props) => ({
    mainMenu: selectors.getMainMenus(state),
    brand: selectors.getBrands(state),
    category: selectors.getCategories(state),
    categoryItems: selectors.getCategoryItems(state),
    sameDayDeliveryMsg: selectors.getSameDayDeliveryMsg(state, props),
    item: selectors.getItems(state),
    cart: selectors.getCart(state),
    isLoggedin: state.user.isLoggedin,
    firstName: state.user.firstName,
    token: state.user.token,
    currentMenu: state.item.currentMenu
  }),
  mapDispatchToProps: {
    addToCart,
    updateCart,
    getItemPrice,
    getPriceChangedItems,
    deleteCart,
    addCurrentMenu
  },
  component: React.memo(Item)
});
