import React from 'react';
import { Redirect } from 'react-router';

import { connect } from '../data/connect';

interface StateProps {
  hasSeenIntroduction: boolean;
}

const HomeOrIntroduction: React.FC<StateProps> = ({ hasSeenIntroduction }: StateProps) => {
  return hasSeenIntroduction ? <Redirect to="/tabs/home" /> : <Redirect to="/introduction" />
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    hasSeenIntroduction: state.user.hasSeenIntroduction
  }),
  component: HomeOrIntroduction
});
