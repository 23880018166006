import React, { useContext } from 'react';
import { IonRouterContext } from '@ionic/react';

interface RedirectToHomeProps {
  addCurrentMenu: Function;
}

const RedirectToHome: React.FC<RedirectToHomeProps> = ({ addCurrentMenu }) => {
  const ionRouterContext = useContext(IonRouterContext);
  addCurrentMenu(0);
  ionRouterContext.push('/tabs/home')
  return null;
};

export default RedirectToHome;
