import React, { useEffect, useState } from 'react';
import { IonButton, IonHeader, IonToolbar, IonContent, IonPage, IonButtons, IonGrid, IonRow, IonCol, IonImg, IonBackButton, IonLabel, IonCard, IonCardHeader, IonCardContent, IonItem, IonText, useIonViewDidEnter, useIonLoading, IonModal, IonTitle } from '@ionic/react';

import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import { loadOrders } from '../data/user/user.actions';
import { IOrder } from '../models/User';
import './MyOrders.scss';
import { RouteComponentProps } from 'react-router';

interface IOwnProps extends RouteComponentProps { };

interface IStateProps {
  orders: IOrder[];
  token: string;
};

interface IDispatchProps {
  loadOrders: typeof loadOrders;
};

interface IMyOrdersProps extends IOwnProps, IStateProps, IDispatchProps { };

const MyOrders: React.FC<IMyOrdersProps> = ({ orders, loadOrders, token, history }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [present, dismiss] = useIonLoading();
  const [isOpen, setIsOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState<IOrder>();

  useEffect(() => {
    isLoading && loadOrders(token);
    setIsLoading(false);
    console.log(orders);
  }, [isLoading]);

  useIonViewDidEnter(() => {
    present({
      message: 'Loading...',
      duration: 1000,
      spinner: 'circles'
    })
    setIsLoading(true);
  });

  
  return (
    <IonPage id="order-list">
      <IonContent fullscreen={true}>
        <IonHeader translucent={true}>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton text="" defaultHref="/tabs/home" />
              <IonLabel>My Orders</IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <>
          {
            orders.length  === 0 ? (
              <IonGrid className="empty-order">
                <IonImg src="assets/img/emptycart.svg" alt="" />
                <p>Your haven't ordered anything yet !</p>
                <IonRow><IonCol></IonCol></IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton routerLink="/tabs/home">Order Now</IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>

            ) : (
              <IonGrid fixed>
                {orders.map(order => (
                  <IonCard className="speaker-card" onClick={() => {
                    setOrderDetails(order)
                    setIsOpen(true) }/*history.push('/tabs/home/AddressBook')*/ 
                    }>
                    <IonCardContent>
                      <IonItem>
                        <IonRow>
                          <IonCol className="ion-no-padding">
                            <h3>Order Id</h3>
                            <h3>Order Date</h3>
                            <h3>Order Status</h3>
                            <h3>Payment Status</h3>
                            <h3>Sub Total (Incl. Dis)</h3>
                            <h3>Delivery Charge</h3>
                            <h3><b>Total</b></h3>
                            
                          </IonCol>
                          <IonCol className="ion-no-padding">
                            
                            <h3 className='right-h3'>{order.orderId.substring(11)}</h3>
                            <h3 className='right-h3'>{order.orderId.substring(0, 10)}</h3>
                            <h3 className='right-h3'><IonText color={order.status === "Initiated" ? "success" : (order.status === "Delivered" ? "primary" : "warning")}><b>{order.status}</b></IonText></h3>
                            <h3 className='right-h3'><IonText color={order.amountPaid == order.totalPrice ? "success" : (Number(order.amountPaid) > 0 ? "warning" : "primary" )}><b>{order.amountPaid == order.totalPrice ? "Paid" : (Number(order.amountPaid) > 0 ? "Partially Paid" : "Not Paid")}</b></IonText></h3>
                            <h3 className='right-h3'>LKR {order.subTotal.toLocaleString('en-US',{ minimumFractionDigits: 2})}</h3>
                            <h3 className='right-h3'>LKR {order.deliveryCharges.toLocaleString('en-US',{ minimumFractionDigits: 2})}</h3>
                            <h3 className='right-h3'><b>LKR {order.totalPrice.toLocaleString('en-US',{ minimumFractionDigits: 2})}</b></h3>
                          </IonCol>
                        </IonRow>
                      </IonItem>
                      </IonCardContent>
                  </IonCard>
                ))}
              </IonGrid>
            )
          }
          <IonModal isOpen={isOpen}>
            <IonHeader>
              <IonToolbar>
                <IonTitle>Order : {orderDetails?.orderId.substring(11)}</IonTitle>
                <IonButtons slot="end">
                  <IonButton color={"primary"} onClick={() => setIsOpen(false)}><h4>X</h4></IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-no-padding">
              <IonGrid fixed className=''>
                <IonRow>
                  <IonCol size='7'><p><img src='images/logo.svg'></img></p></IonCol> 
                </IonRow>
                <IonRow className='detail-col'>
                  <IonCol size='7'><p><b>Item Description</b></p></IonCol> 
                  <IonCol size='2'><p className='ion-text-right'><b>Each</b></p></IonCol> 
                  <IonCol size='1'><p className='ion-text-right'><b>Q.</b></p></IonCol> 
                  <IonCol size='2'><p className='ion-text-right'><b>Price (LKR)</b></p></IonCol> 
                </IonRow>
                { orderDetails?.orderItems && orderDetails?.orderItems.map(orderItem =>
                  { const discountedPrice = Number(orderItem.price) / Number(orderItem.quantity);
                    return <IonRow className='detail-col'>
                      <IonCol size='7'><p className='ion-text-wrap'>{orderItem.name + " ( LKR " + Number(orderItem.unitPrice).toLocaleString('en-US') + " - " + orderItem.discount + "% )"}  </p></IonCol> 
                      <IonCol size='2'><p className='ion-text-right'>{discountedPrice.toLocaleString('en-US')}</p></IonCol> 
                      <IonCol size='1'><p className='ion-text-right'>{orderItem.quantity.toFixed(0)}</p></IonCol> 
                      <IonCol size='2'><p className='ion-text-right'>{orderItem.price.toLocaleString('en-US')}</p></IonCol> 
                    </IonRow>
                  }
                )}
                <br></br>
                
                <IonRow>
                  <IonCol className='no-margin' size='7'><p >Sub Total</p></IonCol> 
                  <IonCol className='no-margin'><p className='ion-text-right'>{Number(orderDetails?.subTotal).toLocaleString('en-US',{ minimumFractionDigits: 2})}</p></IonCol> 
                </IonRow>
                <IonRow>
                  <IonCol className='no-margin' size='7'><p className='ion-text-wrap'>Delivery Charges</p></IonCol> 
                  <IonCol className='no-margin'><p className='ion-text-right'>{orderDetails?.deliveryCharges.toLocaleString('en-US',{ minimumFractionDigits: 2})}</p></IonCol> 
                </IonRow>
                <IonRow>
                  <IonCol className='no-margin' size='7'><p className='ion-text-wrap'><b>Total</b></p></IonCol> 
                  <IonCol className='no-margin'><p className='ion-text-right'><b>{orderDetails?.totalPrice.toLocaleString('en-US',{ minimumFractionDigits: 2})}</b></p></IonCol> 
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonModal>
        </>
      </IonContent>
    </IonPage>
  )
};

export default connect<IOwnProps, IStateProps, IDispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    orders: selectors.getOrders(state),
    token: selectors.getToken(state, OwnProps)
  }),
  mapDispatchToProps: { 
    loadOrders
  },
  component: MyOrders
});
