import { combineReducers } from './combineReducers';
import { sessionsReducer } from './sessions/sessions.reducer';
import { userReducer } from './user/user.reducer';
import { itemReducer } from './items/item.reducer';

export const initialState: AppState = {
  data: {
    schedule: { groups: [] } as any,
    sessions: [],
    speakers: [],
    favorites: [],
    locations: [],
    allTracks: [],
    filteredTracks: [],
    mapCenterId: 0,
    loading: false,
    menuEnabled: true,
    notifications:[],
    cities:[],
    regions:[]
  },
  user: {
    hasSeenIntroduction: false,
    addresses: [],
    paymentMethods: [],
    deliveryCharges: [],
    darkMode: false,
    isLoggedin: false,
    loading: false,
    email:"",
    phone:"",
    token:"",
    guestToken: "",  
    id:"",
    orders: [],
  },
  item: {
    mainMenus: [],
    brands: [],
    category: [],  
    categoryItems: [],  
    items: [],
    cart: [],
    currentMenu: 0,
  }
};

export const reducers = combineReducers({
  data: sessionsReducer,
  item: itemReducer,
  user: userReducer
});

export type AppState = ReturnType<typeof reducers>;