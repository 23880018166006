import { useState, useEffect, useCallback, useMemo } from "react";

export default function useGreeting(name: string = '', isLoggedin:boolean = false) {
    const [greeting, setGreeting] = useState('');


    const time = useMemo(() => new Date().getHours(), []);

    const greet = useCallback(() => {
        const val = (time >= 12 && time < 16 ? "Good Afternoon! " : time >= 16 ? "Good Evening! " : "Good Morning! ") + (isLoggedin  ? name : "")  ;
        setGreeting(val);
    }, [time, name, isLoggedin]);

    useEffect(() => {
        greet()
    }, [time, greet]);

    return greeting;
}
