/**
 * @file My Favourite
 * @author J. Nalinda
 */

import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonGrid, IonRow, IonCol, IonIcon, IonHeader, IonLabel, IonToolbar, IonButtons, IonContent, IonButton, IonBackButton, IonPage, IonBadge, IonImg, IonCard, IonCardTitle, IonCardSubtitle, IonAlert, useIonViewDidEnter, useIonLoading } from '@ionic/react';
import { cartSharp, } from 'ionicons/icons';

import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import { updateCart, addToCart, getItemPrice, getPriceChangedItems, deleteCart } from '../data/items/item.actions';
import { ICart, IItem } from '../models/Item';
import { IUser } from '../models/User';
import './ShoppingCart.scss';
import { itemReducer } from '../data/items/item.reducer';


interface IOwnProps extends RouteComponentProps {
  token: string;
  item: IItem[];
  user: IUser;
  isLoggedin: boolean;
};

interface IStateProps {
  cart: ICart[];
};

interface IDispatchProps {
  addToCart?: typeof addToCart;
  updateCart?: typeof updateCart;
  getItemPrice: typeof getItemPrice;
  getPriceChangedItems: typeof getPriceChangedItems;
  deleteCart: typeof deleteCart;
};

type IMyFavourite = IOwnProps & IStateProps & IDispatchProps;

const MyFavourite: React.FC<IMyFavourite> = ({ item, cart, user, addToCart, updateCart, getItemPrice, getPriceChangedItems, deleteCart, history, token, isLoggedin }) => {

  const [quantity, setQuantity] = useState(1);
  const [cartQty, setCartQty] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [cartItem, setCartItem] = useState<IItem>();
  const [showAlert, setShowAlert] = useState(false);
  const [present, dismiss] = useIonLoading();

  useEffect(() => {
    isLoading && user.item && user.item.map(favouriteItem => {
        const itemDetails = item.find(val => val._id === favouriteItem);
        getItemPrice!(favouriteItem, itemDetails!.price, itemDetails!.discountPercentage, token, priceCallback);
      }
    );
    setIsLoading(false);
  },[isLoading]);

  useEffect(() => {
    if (cart !== undefined && cart.length > 0)
      setCartQty(cart.length);
  });

  useIonViewDidEnter(() => {
    present({
      message: 'Loading...',
      duration: 1000,
      spinner: 'circles'
    })
    setIsLoading(true);
  });

  const updateQuantity = (qty: any) => {
    if (qty > 0) {
      setQuantity(qty);
    }
  }

  useEffect(() => {
    if(cartItem != undefined && cartItem._id != '' ) {
      getItemPrice!(cartItem._id, cartItem.price, cartItem.discountPercentage, token, addToCartCallback);
      addToCartFunction(cartItem);
    }
  }, [cartItem]);

  const priceCallback = useCallback(async ( data: any ) => {
    const { status } = data;
    await status && getPriceChangedItems!(token);
    await status && setIsLoading(true);

  }, []);

  const addToCartCallback = useCallback(async ( data: any ) => {
    const { status, price } = data;
    //await status ? itemPriceRef.current = price : itemPriceRef.current = 0;
    await status && setShowAlert(true);
  }, []);

  const addToCartFunction = async (addingItem: IItem) => {
    let price: number = 0, discount: number = 0, isExisting: boolean = false, itemQty: number = 0, isCartAvail: boolean = false, _id: string = '';
    
    if (cart !== undefined && cart.length > 0) {
      isCartAvail = true;
      const existingCartItem = cart.find(cartItem => addingItem._id === cartItem.orderitems.item);
      if (existingCartItem) {
        _id = existingCartItem.orderitems._id;
        isExisting = true;
        itemQty = existingCartItem!.orderitems.quantity;
      }
    }

    itemQty += 1;
    price = addingItem.price * itemQty;
    discount = addingItem.discountPercentage > 0 ?  addingItem.discountPercentage : (addingItem.originalPrice - addingItem.price) * 100 / addingItem.originalPrice ;
    
    if (!isExisting) {
      let formData = {
        data: {
          item: addingItem._id,
          quantity: itemQty,
          name: addingItem.desc,
          price,
          unitPrice: addingItem.originalPrice,
          purchasePrice: addingItem.purchasePrice,
          originalPrice: addingItem.originalPrice,
          discount,
          isCartAvail
        }
      }
      const response = await addToCart!(formData, token);
      //setCartQty(cartQty + 1); 
      setCartQty(cart.length);
    }
    else {
      let formData = {
        data: {
          _id,
          quantity: itemQty,
          name: addingItem.desc,
          price,
          unitPrice: addingItem.price,
          purchasePrice: addingItem.purchasePrice,
          originalPrice: addingItem.originalPrice,
          discount
        }
      }
      updateCart!(formData, token);
      updateQuantity(itemQty);
    }
    setCartItem(undefined);
  }

  return (
    <IonPage id="shopping-cart">
      <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass='my-custom-class'
          header={'Price Changed !!'}
          message={`Gusta has just updated item prices and your cart will be erased. Please add your favourite items to cart again.<br/><br/>Sorry for the inconvenience.`}
          buttons={[
            {
              text: 'Ok',
              handler: () => {
                deleteCart!("priceChanged", 0, token);
                setIsLoading(true);
              }
            }
          ]}
        />
      <IonContent fullscreen={true}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton text="" defaultHref="/tabs/home" />
              <IonLabel>My Favourite</IonLabel>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={() => isLoggedin ? history.push('/tabs/shoppingcart', { direction: 'none' }) : history.push('/login', { direction: 'none' })}>
                <IonIcon icon={cartSharp}></IonIcon>
              </IonButton>
              <IonBadge slot="start">{cart !== undefined && isLoggedin && cart.length > 0 ? cartQty : ""}</IonBadge>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        {
          (user.item == undefined || user.item == null)
            ? <IonGrid className="empty-cart">
              <IonImg src="assets/img/emptycart.svg" alt="" />
              <p className='no-favourite'>Your don't have any item in your Favourite List!</p>
              <IonRow><IonCol></IonCol></IonRow>
              <IonRow>
                <IonCol>
                  <IonButton routerLink="/tabs/home">Order Now</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            :
            user.item.map(favouriteItem => {
              const itemDetails = item.find(val => val._id === favouriteItem);
              return <IonCard className="shopping-card">
                <IonGrid>
                  <IonRow>
                    <IonCol size='2' className="image-ion-col" >
                      {itemDetails?.images !== undefined
                        ? itemDetails?.images.map(image => (
                          <IonImg src={image.url} alt="item image" />))
                        : <IonImg src="assets/img/no-image-small.png" alt="item image" />
                      }
                    </IonCol>
                    <IonCol size='6.4' className="text-ion-col">
                      <IonCardTitle>{itemDetails?.name} </IonCardTitle>
                      <IonCardSubtitle>LKR: {Number(itemDetails?.price).toLocaleString('en-US',{ minimumFractionDigits: 2})} </IonCardSubtitle>
                      <IonCardSubtitle>{itemDetails?.desc} </IonCardSubtitle>
                    </IonCol>
                    <IonCol size='2.2' className="fav-ion-button">
                      <IonButton onClick={() => setCartItem(itemDetails!)} > <p>+ &nbsp;&nbsp;&nbsp;&nbsp;<IonIcon icon={cartSharp}></IonIcon></p> </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            })
        }
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: (state, ownProps) => ({
    item: state.item.items,
    cart: selectors.getCart(state),
    user: selectors.getUserDetails(state), //state.user.user[0],
    token: state.user.token,
    isLoggedin: state.user.isLoggedin
  }),
  mapDispatchToProps: {
    addToCart,
    updateCart,
    getItemPrice,
    getPriceChangedItems, 
    deleteCart
  },
  component: MyFavourite
});
