import { Preferences } from '@capacitor/preferences';
import { Schedule, Session } from '../models/Schedule';
import { Speaker } from '../models/Speaker';
import { Location } from '../models/Location';
import { Category } from '../models/Category';
import { IMainMenu, IBrand, ICategoryItems, IItem, ICategory, ICart } from '../models/Item';
import { IAddress, IDeliveryCharges, IPaymentMethod, INotification, ICity, IOrder, IUser, IRegion } from '../models/User';
import { userInfo } from 'os';
import {ENV} from '../env/env'

// import { valuesIn } from 'lodash';
// const { Storage } = Plugins;

const HOST_SECURE =  ENV.HOST_SECURE
const HOST_UNSECURE =  ENV.HOST_UNSECURE

const mainMenuUrl = HOST_SECURE + "administrator/permission/getMainMenu";
const brandUrl = HOST_SECURE + "basic_data/getBrand";
const categoryUrl = HOST_SECURE + "basic_data/getCategories";
const categoryItemUrl = HOST_SECURE + "basic_data/item/getCategoryItem";
const itemUrl = HOST_SECURE + "basic_data/item/getItemsForMobile";
const itemPriceUrl = HOST_SECURE + "basic_data/item/getItemPriceById";
const cartUrl = HOST_SECURE + "shoppingcart/getShoppingCart";
const addToCartUrl = HOST_SECURE + "shoppingcart/addShoppingCart";
const updateCartUrl = HOST_SECURE + "shoppingcart/updateShoppingCart";
const deleteCartUrl = HOST_SECURE + "shoppingcart/deleteShoppingCart";
const addToFavouriteUrl = HOST_SECURE + "shoppingcart/addToFavourite";
const addOrderUrl = HOST_SECURE + "ordering/addOrder";
const addressUrl = HOST_SECURE + "addresses/getAddresses";
const paymentMethodUrl = HOST_SECURE + "basic_data/paymentmethod/getPaymentMethods";
const deliveryChargesUrl = HOST_SECURE + "basic_data/deliverycharges/getDeliveryCharges";
const login_url = HOST_UNSECURE + "login/userValidation";
const guestUrl = HOST_UNSECURE + "login/guestUser";
const getUserUrl = HOST_SECURE + "administrator/user/getUser";
const getUserByPhoneUrl = HOST_SECURE + "administrator/user/getUserByPhone";
const create_user_url = HOST_SECURE + "administrator/user/addUser";
const verify_user_url = HOST_SECURE + "administrator/user/verifyPhone";
const send_verify_sms_url = HOST_SECURE + "administrator/user/resendPhoneVerifyCode";
const get_notification_url = HOST_SECURE + "administrator/notifications/getNotifications";
const set_password_url = HOST_SECURE + "administrator/user/setPassword";
const update_user_url = HOST_SECURE + "administrator/user/updateUser";
const deleteAddressUrl = HOST_SECURE + "addresses/deleteAddress";
const addAddressUrl = HOST_SECURE + "addresses/addAddress";
const updateAddressUrl = HOST_SECURE + "addresses/updateAddress";
const getCitiesUrl = HOST_SECURE + "basic_data/getCity";
const getOrdersUrl = HOST_SECURE + "ordering/getOrdersWithOrderItems";
const getOrderItemsUrl = HOST_SECURE + "ordering/getOrderItemsByRef";
const getRegion = HOST_SECURE + "/basic_data/getRegion";

const HAS_LOGGED_IN = 'hasLoggedIn';
const HAS_SEEN_INTRODUCTION = 'hasSeenIntroduction';
const PASSWORD = 'password';
const USERNAME = 'username';
const LOGGED_TOKEN = 'token';

const getOptions: any = (token: string, isPostRequest: boolean = false, body: string = '') => {
  if (isPostRequest)
    return {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body
    }
  else
    return { headers: { Authorization: token } }
}


export const getLoginData = async () => {
  //let addresses, paymentMethods, deliveryCharges, token2, , hasSeenIntroduction, username, data;
  const response = await Promise.all([
    Preferences.get({ key: HAS_LOGGED_IN }),
    Preferences.get({ key: HAS_SEEN_INTRODUCTION }),
    Preferences.get({ key: USERNAME }),
    Preferences.get({ key: PASSWORD }),
    fetch(guestUrl, getOptions('', true, ''))
  ])

  const isLoggedin = response[0].value === 'true' || false;
  const hasSeenIntroduction = response[1].value === 'true' || false;
  const username = response[2].value || undefined;
  const password = response[3].value || undefined;
  const guest = await response[4].json();
  const { token } = await guest;
  const data = {
    isLoggedin,
    hasSeenIntroduction,
    username,
    password,
    token,
    guestToken: token
  }
  return data;
}

export const getUserData = async () => {

  const token = (await Preferences.get({ key: LOGGED_TOKEN })).value;
  if (token != null) {
    const userResponse = (await fetch(getUserUrl, getOptions(token))).json();
    const user = await userResponse as IUser[];
    try {
      const _id = user[0]._id;
      const userType = await user[0].userType;
      const firstName = await user[0].name.first;
      const addressResponse = (await fetch(addressUrl, getOptions(token))).json();
      const addresses = await addressResponse as IAddress[];
      // if (addresses.length == 0)
      //   return {};
      const response = await Promise.all([
        fetch(paymentMethodUrl + `?userType=${userType}`, getOptions(token)),
        fetch(deliveryChargesUrl + `?userType=${userType}`, getOptions(token)),
      ]);
      const paymentMethods = await response[0].json() as IPaymentMethod[];
      const deliveryResponse = await response[1].json() /*as IDeliveryCharges[]*/;
      const deliveryCharges = parseDeliveryCharges(deliveryResponse);

      const data = {
        _id,
        addresses,
        token,
        paymentMethods,
        deliveryCharges,
        firstName,
        user
      }
      return data;
    }
    catch {
      return {};
    }
  }
  return {};
}

export const getItemData = async (token: string) => {
  const postData = { headers: { Authorization: token } };

  const response = await Promise.all([
    fetch(mainMenuUrl, postData),
    fetch(brandUrl, postData),
    fetch(categoryUrl, postData),
    fetch(categoryItemUrl, postData),
    fetch(itemUrl, postData),
    fetch(cartUrl, postData)
  ]);
  const mainMenus = await response[0].json() as IMainMenu[];
  const brands = await response[1].json() as IBrand[];
  const categories = await response[2].json() as ICategory[];
  const categoryItems = await response[3].json() as ICategoryItems[];
  const itemsResponse = await response[4].json();
  const items = itemsResponse['item'] as IItem[];
  const cart = await response[5].json() as ICart[];

  //const categorys = responseData['item'] as Category[];
  const data = {
    mainMenus,
    brands,
    categories,
    categoryItems,
    items,
    cart
  }

  return data;
}

export const getPriceChangedItemsData = async (token: string) => {
  const postData = { headers: { Authorization: token } };

  const response = await Promise.all([
    fetch(itemUrl, postData),
  ]);

  const itemsResponse = await response[0].json();
  const items = itemsResponse['item'] as IItem[];
  
  return items;
}


export const getBasicData = async (token: string) => {
  const postData = { headers: { Authorization: token } };
  const response = await Promise.all([
    fetch(get_notification_url, postData),
    fetch(getCitiesUrl, postData),
    fetch(getRegion, postData)
  ]);

  // const responseData = await response[0].json();
  // const schedule = responseData.schedule[0] as Schedule;
  // const sessions = parseSessions(schedule);
  // const speakers = responseData.speakers as Speaker[];
  // const locations = await response[1].json() as Location[];
  // const allTracks = sessions
  //   .reduce((all, session) => all.concat(session.tracks), [] as string[])
  //   .filter((trackName, index, array) => array.indexOf(trackName) === index)
  //   .sort();
  const notifications = await response[0].json() as INotification[];
  const cities = await response[1].json() as ICity[];
  const regions = await response[2].json() as IRegion[];
  const data = {
    // speakers,
    // allTracks,
    // filteredTracks: [...allTracks],
    notifications,
    cities,
    regions
  }
  return data;
}


export const loginUserData = async (email: string, password: string, token: string) => {
  try {
    const body = JSON.stringify({ email: email, password: password });
    const response = await Promise.all([
      fetch(login_url, getOptions(token, true, body)),
    ])

    let result = await response[0].json();
    let user = { token: "", email: "", phone: "", isPhoneVerified: false, isApproved: false, /*name:"",*/ gender: "", whatsapp: "", _id: "", firstName: "" };
    let data = { status: "Fail", message: "Error occured. Please try again later.", user: user }
    if (result.message == "Sucess" && result.result.isPhoneVerified && result.result.isApproved) {
      await Preferences.set({ key: LOGGED_TOKEN, value: result.token });
      await Preferences.set({key: HAS_LOGGED_IN, value: 'true'});
      await Preferences.set({key: USERNAME, value: email});
      await Preferences.set({key: PASSWORD, value: password});

      data = {
        status: "Success",
        message: "",
        user: {
          token: result.token, email: result.result.email, phone: result.result.phone, isPhoneVerified: result.result.isPhoneVerified,
          isApproved: result.result.isApproved, /*name: result.result.name.first + " " + result.result.name.last*/
          gender: result.result.gender, whatsapp: result.result.whatsapp, _id: result.result._id, firstName: result.result.name.first
        }
      }
    } else if (result.message == "Sucess" && !result.result.isPhoneVerified) {
      data = {
        status: "Fail",
        message: "Phone is not verified",
        user: {
          token: result.token, email: result.result.email, phone: result.result.phone, isPhoneVerified: result.result.isPhoneVerified,
          isApproved: result.result.isApproved, /*name: result.result.name,*/ gender: result.result.gender, whatsapp: result.result.whatsapp, _id: result.result._id, firstName: result.result.name.first
        }
      }
    } else {
      data = {
        status: "Fail",
        message: !result.result.isApproved ? "Your account is blocked. Please contact Gusta !" : "Invalid Email or Password",
        user: user
      }
    }

    return data;
  } catch (error) {
    return {
      status: "Fail",
      message: "Invalid Email or Password",
      user: { token: "", email: "", isPhoneVerified: false, isApproved: false }
    }
  }
}

export const setIsLoggedInData = async (isLoggedIn: boolean) => {
  await Preferences.set({ key: HAS_LOGGED_IN, value: JSON.stringify(isLoggedIn) });
  !isLoggedIn && await Preferences.remove({ key: LOGGED_TOKEN }) ;
  !isLoggedIn && await Preferences.remove({ key: PASSWORD }) ;
}

export const signupUserData = async (fullName: string, email: string, password: string, userType: string,
  phone: string, gender: string, isPhoneVerified: boolean, isEmailVerified: boolean, isApproved: boolean, isAdmin: boolean, whatsapp: string, token: string) => {
  let nameParts = fullName.split(' ');
  //prepare slug
  let slug = nameParts.join("-").toLowerCase();

  let firstName = nameParts[0];
  nameParts = nameParts.splice(1);
  let lastName = nameParts.join(' ');
  // prepare name obj
  let name = { first: firstName, last: lastName };
  let otp = Math.floor(100000 + Math.random() * 900000);;
  let isExpired = false;
  let isMobile = true;
  // const options = {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     // 'Authorization':  token
  //   },
  //   body: JSON.stringify({data:{name, email, password, userType, phone, gender, otp, isPhoneVerified, isEmailVerified, isApproved, isAdmin,isExpired,isMobile,slug}})
  // }
  const body = JSON.stringify({ data: { name, email, password, userType, phone, whatsapp, gender, otp, isPhoneVerified, isEmailVerified, isApproved, isAdmin, isExpired, isMobile, slug } })
  const response = await Promise.all([
    fetch(create_user_url, getOptions(token, true, body)),
  ])
  try {
    let result = await response[0].json();
    let tmpData = result;
    let data = { status: "Fail", message: "Error occured. Please try again later." }
    if (tmpData == 11000) {
      data = {
        status: "Fail",
        message: "Email or Phone Number is already exsisting."
      }
    } else {
      data = {
        status: "Success",
        message: "User created Successfully."
      }
    }

    return data;
  } catch (error) {
    console.log(error);
    return {
      status: "Fail",
      message: "Error occured. Please try again later."
    }
  }
}

export const googleSignupUserData = async (fullName: string, email: string, password: string, userType: string,
  phone: string, gender: string, isPhoneVerified: boolean, isEmailVerified: boolean, isApproved: boolean, isAdmin: boolean, whatsapp: string, token: string) => {
  
  let nameParts = fullName.split(' ');
  //prepare slug
  // let slug = nameParts.join("-").toLowerCase();

  let firstName = nameParts[0];
  nameParts = nameParts.splice(1);
  let lastName = nameParts.join(' ');
  // prepare name obj
  let name = { first: firstName, last: lastName };
  // let otp = Math.floor(100000 + Math.random() * 900000);;
  // let isExpired = false;
  // let isMobile = true;
  let user = { token: "", email: "", phone: "", isPhoneVerified: false, isApproved: false, /*name:"",*/ gender: "", whatsapp: "", _id: "", name: name};
  let data = { status: "Fail", message: "Error occured. Please try again later.", user: user };
  
  try {
    data = {
      status: "Success",
      message: "",
      user: {
        token: token, email: email, phone: phone, isPhoneVerified: isPhoneVerified,
        isApproved: isApproved, gender: gender, whatsapp: whatsapp,  _id: "", name: name, 
      }
    };

    return data;
  } catch (error) {
    console.log(error);
    return {
      status: "Fail",
      message: "Error occured. Please try again later.",
      user: user
    }
  }
}

export const saveUserData = async (_id: string, firstname: string, lastname: string, password: string, phone: string, oldPhone: string, gender: string, whatsapp: string, addressId: string, token: string) => {

  let slug = firstname.toLowerCase();
  let name = { first: firstname, last: lastname };

  let otp = Math.floor(100000 + Math.random() * 900000);;
  let isExpired = false;
  let isPhoneVerified = phone == oldPhone ? true : false;

  const body = JSON.stringify({ data: { _id, name, password, phone, whatsapp, gender, otp, isPhoneVerified, isExpired, slug } });
  const response = await Promise.all([
    fetch(update_user_url, getOptions(token, true, body)),
  ])

  try {
    let result = await response[0].json();
    let tmpData = result.data;
    let data = { status: "Fail", message: "Error occured. Please try again later." }

    if (result === 11000) {
      data = {
        status: "Fail",
        message: "Email, phone or User name already exsists."
      }
    } else {
      data = {
        status: "Success",
        message: "User updated successfully."
      }
    }

    return data;
  } catch (error) {
    console.log(error);
    return {
      status: "Fail",
      message: "Error occured. Please try again later."
    }
  }
}

export const verifyPhoneData = async (otp: string, email: string, phone: string, token: string) => {

  const body = JSON.stringify({ data: { otp, email, phone } });
  const response = await Promise.all([
    fetch(verify_user_url, getOptions(token, true, body)),
  ])
  try {
    let result = await response[0].json();
    let data = {}
    if (result.data == 'Success') {
      data = {
        status: "Success",
        message: "Phone verified successfuly"
      }
    } else {
      data = {
        status: "Fail",
        message: result.data
      }
    }
    return data;
  } catch (error) {
    console.log(error);
    return {
      status: "Fail",
      message: "Network Failure. Please try again later."
    }
  }
}

export const sendVerifySMSData = async (phone: string, email: string, token: string) => {
  let otp = Math.floor(100000 + Math.random() * 900000);
  try {

    // const options = {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     // 'Authorization':  token
    //   },
    //   body: JSON.stringify({data:{otp,phone,email}})
    // }
    const body = JSON.stringify({ data: { otp, phone, email } });
    const response = await Promise.all([
      fetch(send_verify_sms_url, getOptions(token, true, body)),
    ])

    let result = await response[0].json();

    let data = {}
    if (result.data == 'Success') {
      data = {
        status: "Success",
        message: "SMS is sent successfuly"
      }
    } else {
      data = {
        status: "Fail",
        message: result.data
      }
    }

    return data;
  } catch (error) {
    console.log(error);
    return {
      status: "Fail",
      message: "Error occured. Please try again later."
    }
  }
}

export const sendForgotPasswordOtpData = async (phone: string, token: string) => {
  const body = JSON.stringify({ phone: phone.trim() });
  const response = await Promise.all([
    fetch(getUserByPhoneUrl, getOptions(token, true, body))
  ])
  try {
    let result = await response[0].json();
    result = result[0];
    //return result.data;
    let data = { status: "Success", message: result.otp, _id: result._id }
    return data;
  } catch (error) {
    console.log(error);
    return {
      status: "Fail",
      message: "You are not registered with this Phone Number. Please Try Again !",
      _id: ""
    }
  }
}

export const setUserPasswordData = async (_id: string, password: string, token: string) => {
  const body = JSON.stringify({ data: { _id, password } });
  const response = await Promise.all([
    fetch(set_password_url, getOptions(token, true, body)),
  ])

  try {
    let result = await response[0].json();
    let tmpData = result.data;
    let data = { status: "Fail", message: "Error occured. Please try again later." }
    if (tmpData == 11000) {
      data = {
        status: "Fail",
        message: "Error occured. Please try again later."
      }
    } else {
      data = {
        status: "Success",
        message: "Password updated successfully."
      }
    }

    return data;
  } catch (error) {
    console.log(error);
    return {
      status: "Fail",
      message: "Error occured. Please try again later."
    }
  }
}


export const setHasSeenIntroductionData = async (hasSeenIntroduction: boolean) => {
  console.log("DATA", HAS_SEEN_INTRODUCTION, hasSeenIntroduction)
  await Preferences.set({ key: HAS_SEEN_INTRODUCTION, value: JSON.stringify(hasSeenIntroduction) });
}

export const setUsernameData = async (username?: string) => {
  if (!username) {
    await Preferences.remove({ key: USERNAME });
  } else {
    await Preferences.set({ key: USERNAME, value: username });
  }
}

export const addToCartData = async (data: object, token: string) => {
  // const options = {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     // 'Authorization':  token
  //   },
  //   body: JSON.stringify(data)
  // }
  const body = JSON.stringify(data);
  const response = await Promise.all([
    fetch(addToCartUrl, getOptions(token, true, body))
      .then(async _res => {
        const res = await fetch(cartUrl, getOptions(token));
        return await res.json();
      })
      .catch(async _res => { return {} })
    //fetch(cartUrl,postData)
  ])
  //const cart = await response[1].json() ;
  //return await response[1].json() as ICart[];
  return response[0];
}

export const updateCartData = async (data: object, token: string) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // 'Authorization':  token
    },
    body: JSON.stringify(data)
  }
  //var response = await fetch(updateCartUrl, options);
  const body = JSON.stringify(data);
  const response = await Promise.all([
    fetch(updateCartUrl, getOptions(token, true, body))
      .then(async _res => {
        const res = await fetch(cartUrl, getOptions(token));
        return await res.json();
      })
      .catch(async _res => { return {} })
  ]);
  return response[0];
}

export const deleteCartData = async (_id: string, count: number, token: string) => {
  // const options = {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     // 'Authorization':  token
  //   },
  //   body: JSON.stringify({_id, count})
  // }
  const body = JSON.stringify({ _id, count });

  const response = await Promise.all([
    fetch(deleteCartUrl, getOptions(token, true, body))
      .then(async _res => {
        const res = await fetch(cartUrl, getOptions(token));
        return await res.json();
      })
      .catch(async _res => { return {} })
  ]);
  return response[0];
}

export const addOrderData = async (order: object, token: string) => {
  // const options = {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     // 'Authorization':  token
  //   },
  //   body: JSON.stringify(order)
  // }
  const body = JSON.stringify(order);
  const response = await fetch(addOrderUrl, getOptions(token, true, body));
  return response;
}

export const addToFavouriteData = async (data: object, token: string) => {
  const body = JSON.stringify(data);
  const response = await Promise.all([
    fetch(addToFavouriteUrl, getOptions(token, true, body)),
    fetch(cartUrl, getOptions(token)),
  ]);
  return await response[1].json() as ICart[];
}

export const addAddressData = async (contactName: string, email: string, phone: string, streetAddress: string, city: string,
  isDelivery: boolean, isBilling: boolean, user: string, token: string) => {

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify({ data: { contactName, email, phone, streetAddress, city, isDelivery, isBilling, user } })
  }
  // {data:{contactName,email,phone,streetAddress,city,isDelivery,isBilling}}
  const body = JSON.stringify({ data: { contactName, email, phone, streetAddress, city, isDelivery, isBilling, user } });
  const response = await Promise.all([
    fetch(addAddressUrl, getOptions(token, true, body)),
  ])

  return await response[0].json() as IAddress[];
}

export const getUserAddressesData = async (token: string) => {
  const response = await Promise.all([
    fetch(addressUrl, getOptions(token)),
  ]);

  const addresses = await response[0].json() as IAddress[];

  const data = {
    addresses
  }
  return data;
}

export const getUserOrdersData = async (token: string) => {
  const response = await Promise.all([
    fetch(getOrdersUrl, getOptions(token)),
  ]);   

  const orders = await response[0].json() as IOrder[];
  const data ={
    orders
  }
  return data;
}

export const getItemPriceData = async (_id: string, token: string) => {
  const body = JSON.stringify({ data: { _id } });
  const response = await Promise.all([
    fetch(itemPriceUrl, getOptions(token, true, body))
  ]);

  const itemPrice = await response[0].json() as IItem;
  const data = itemPrice;
  return data;
}

export const getOrderItemsData = async (_id: string, token: string) => {
  const body = JSON.stringify( { _id });
  const response = await Promise.all([
    fetch(getOrderItemsUrl, getOptions(token, true, body))
  ]);

  const orderItems = await response[0].json() as IOrder[];
  const data = {
    orderItems
  }
  return data;
}

export const deleteAddressData = async (_id: string, token: string) => {
  const body = JSON.stringify({ data: { _id } });
  const response = await Promise.all([
    fetch(deleteAddressUrl, getOptions(token, true, body)),
  ]);
  return await response[0];
}

function parseSessions(schedule: Schedule) {
  const sessions: Session[] = [];
  schedule.groups.forEach(g => {
    g.sessions.forEach(s => sessions.push(s))
  });
  return sessions;
}

function parseDeliveryCharges(deliveryResponse: object[]) {
  const deliveryCharges: IDeliveryCharges[] = [];
  deliveryResponse.map((val: any) => deliveryCharges.push(val.deliverycharges));
  return deliveryCharges;
}
