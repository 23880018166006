import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonImg, IonHeader, IonToolbar, IonContent, IonPage, IonButtons, IonMenuButton, IonButton, IonList, IonItem, IonLabel, IonInput, IonText, IonRouterLink } from '@ionic/react';
import { useForm } from 'react-hook-form';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { setIsLoggedIn, setUsername, loginUser, googleLoginUser } from '../data/user/user.actions';
import { connect } from '../data/connect';
import './Login.scss';

interface OwnProps extends RouteComponentProps { }

interface StateProps {
  token: string;
  savedUserName : string,
  savedPassword : string
}

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
  loginUser: typeof loginUser;
  googleLoginUser: typeof googleLoginUser;
}

interface LoginProps extends OwnProps, StateProps, DispatchProps { }

const Login: React.FC<LoginProps> = ({ setIsLoggedIn, history, setUsername: setUsernameAction, loginUser, savedUserName, savedPassword, googleLoginUser, token }) => {

  const [username, setUsername] = useState(savedUserName);
  const [password, setPassword] = useState(savedPassword);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorText, setLoginErrorText] = useState('');
  const { register, handleSubmit } = useForm();

  const login = async (data:any) => {
    // e.preventDefault();
    setUsername(data.username);
    setPassword(data.password);
    setFormSubmitted(true);
    if (!data.username) {
      setUsernameError(true);
    }else{
      setUsernameError(false);
    }
    if (data.password === '' || !data.password) {
      setPasswordError(true);
    }else{
      setPasswordError(false);
    }
  };

  useEffect(() => {
    GoogleAuth.initialize();
  }, []);

  const googleSignIn = async () => { 

    const user = await  GoogleAuth.signIn();

    if (user) {
      // console.log(user.name);
      // console.log(user.givenName);
      // console.log(user.familyName);
      // console.log(user.imageUrl);
      // console.log(user.email);

      googleLoginUser(user.name, user.email, "", "", "", "", token, createUserCallback,loginCallback);
    }
  } 
  
  useEffect(() => {
    if (formSubmitted && !usernameError && !passwordError) {
      console.log("in 1")
      loginUser(true, username.toLowerCase().trim(), password, token, loginCallback);
    }
    console.log("in 2")

    setFormSubmitted(false);
  }, [formSubmitted, usernameError, passwordError]);

  const createUserCallback = useCallback((data: { status: string, message: string }) => {
    // alert(data.status);
    if (data.status === "Success") {
      history.push('/UpdatePhone', { direction: 'none' });
    } else {
      //  setUserCreateError(true);
      //  setUserCreateErrorText(data.message);
       setFormSubmitted(false);
    }
  }, []);

  const loginCallback = useCallback((data: { status: string, message: string }) => {
    if (data.status === "Success") {
      setIsLoggedIn(true);
      setPasswordError(false);
      setUsernameAction(username);
      history.push('/tabs/home', { direction: 'none' });
    } else if (data.status === "Fail" && data.message === "Phone is not verified") {
      setIsLoggedIn(false);
      setUsernameAction("");
      history.push('/verifyPhone', { direction: 'none' });
    } else {
      setLoginError(true);
      setLoginErrorText(data.message);
      setFormSubmitted(false);
    }
  }, []);

  return (
    <IonPage id="login-page">
      <IonContent>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <p className="login-title">Sign In</p>

        <form  onSubmit={ handleSubmit(login) }>
          <IonList className="input-list">
            <IonItem lines="none">
              <IonInput {...register("username")} className="input-text" placeholder='Enter Email' name="username" type="text" value={username} spellCheck={false} autocapitalize="off" onIonChange={e => setUsername(e.detail.value!)}
                required>
              </IonInput>
            </IonItem>

            {usernameError && <IonText color="danger">
              <p className="ion-padding-start">
                Username is required
              </p>
            </IonText>}

            <IonItem lines="none">
              <IonInput {...register("password")} className="input-text" placeholder="Enter Password" name="password" type="password" value={password} onIonChange={e => setPassword(e.detail.value!)}>
              </IonInput>
            </IonItem>

            {passwordError && <IonText color="danger">
              <p className="ion-padding-start">
                Password is required
              </p>
            </IonText>}

            {loginError && <IonText color="danger">
              <span className="ion-text-center ion-padding-start">
                {loginErrorText}
              </span>
            </IonText>}

            <IonItem lines="none">
              <IonRouterLink text-right routerLink='/forgotPassword' color='medium' /*className="ion-alighn-right ion-no-padding"*/>
                <IonLabel >Forgot your password?</IonLabel>
              </IonRouterLink>
            </IonItem>

            <IonItem lines="none">
              <IonButton type="submit" expand="block" className="sign-in-button">Sign In</IonButton>
            </IonItem>
            <IonItem mode='md' lines="none" routerLink="/signup" className="ion-text-center ion-padding-top" >
              <IonContent >
                <span color='medium'>New to Gusta? </span><span>Signup</span>
              </IonContent>
            </IonItem>

            <IonItem lines="none" className="ion-text-center" >
              <IonContent >
                <span>Or continue with </span>
              </IonContent>
            </IonItem>

            <IonItem lines="none">
            <IonButton className="login-button" onClick={googleSignIn} expand="block" fill="clear" color=""><IonImg src="images/google.png"></IonImg></IonButton>
            <span onClick={googleSignIn} >Google</span>
            </IonItem>
          </IonList>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    token: state.user.token,
    savedUserName: state.user.username,
    savedPassword : state.user.password
  }),
  mapDispatchToProps: {
    setIsLoggedIn,
    setUsername,
    loginUser,
    googleLoginUser
  },
  component: Login
})
