export enum Type {
  MainMenu = 'M',
  Brand = 'B',
  Category = 'C',
  Item = 'I'
}

interface IMenus<T> {
  _id: string;
  name: string;
  desc: string;
  image: {
    url: string;
  }
  sortOrder: number;
  brand: string;
  mobileDescription: string;
  purchasePrice:number;
  originalPrice: number;
  price: number;
  discountPercentage: number;
  priceUnit: string;
  duration: number;
  durationUnit: string;
  isPromotion: boolean;
  isFastDelivery: boolean;
  save: number;
  type: T;
}

export interface IMainMenu extends IMenus<Type> {
  type: Type.MainMenu;
}

export interface IBrand extends IMenus<Type> {
  type: Type.Brand;
}

export interface ICategory extends IMenus<Type> {
  type: Type.Category;
}

export interface IList extends IMenus<string> {
  // mobileDescription: string,
  // purchasePrice:number,
  // originalPrice: number,
  // price: number,
  // discountPercentage: number,
  // priceUnit: string,
  // duration: number,
  // durationUnit: string,
  // isPromotion: boolean,
  // save: number;
}

export interface ICategoryItems {
  _id: string;
  item: string;
  category: ICategoryChildren[];
}

export interface ICategoryChildren {
  _id: string;
  item: string;
  children: ICategoryChildren[];
}

export interface IItem {
  _id: string;
  name: string;
  desc: string;
  mobileDescription: string;
  brand: string;
  discountPercentage: number;
  duration: number;
  durationUnit: string;
  images: Array<{
    url: string;
  }>
  isFastDelivery: boolean;
  isPromotion: boolean;
  purchasePrice: number;
  originalPrice: number;
  price: number;
  priceUnit: string;
  product: string;
  qty: number;
  sortOrder: number;
  active: boolean;
  type: Type.Item;
  currentMenu:number;
}

export interface ICart {
  _id: string;
  user: string;
  orderitems: {
    _id: string;
    item: string;
    quantity: number;
    url: string;
    price: number;
    discount: number;
    name: string;
    isFastDelivery : boolean; 
  }
}
