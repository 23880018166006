/**
 * @file Shooping cart handling
 * @author J. Nalinda
 */
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonGrid, IonRow, IonCol, IonIcon, IonHeader, IonLabel, IonToolbar, IonButtons, IonContent, IonButton, IonBackButton, IonPage, IonImg, IonCard, IonAlert, IonCardTitle, IonCardSubtitle, useIonViewDidEnter, IonCardHeader, IonCardContent, } from '@ionic/react';
import { trash } from 'ionicons/icons';

import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import { ICart, IItem } from '../models/Item';
import { updateCart, deleteCart, setCart } from '../data/items/item.actions';
import './ShoppingCart.scss';

//interface IOwnProps extends RouteComponentProps {}

interface IOwnProps extends RouteComponentProps {
  token: string;
  isLoggedin: boolean;
};

interface IStateProps {
  item: IItem[];
  cart: ICart[];
  minOrderValue: number;
};

interface IDispatchProps {
  updateCart?: typeof updateCart;
  deleteCart?: typeof deleteCart;
  setCart?: typeof setCart;
};

type IShoppingCartProps = IOwnProps & IStateProps & IDispatchProps;

const ShoppingCart: React.FC<IShoppingCartProps> = ({ item, cart, minOrderValue, updateCart, deleteCart, setCart, history, token, isLoggedin }) => {

  const [cartTotal, setCartTotal] = useState(0);
  const [itemQty, setItemQty] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [showCartExpiry, setShowCartExpiry] = useState(false);
  const [itemId, setItemId] = useState('');
  const [minOrderValueError, setMinOrderValueError] = useState(false);

  useEffect(() => {
    if (cart !== undefined && cart.length > 0) {
      const total = cart.reduce((total, currentValue) => total = total + currentValue.orderitems.price, 0);
      setCartTotal(total);
    }
    else setCartTotal(0);
  });

  // useIonViewDidEnter(async () => {
  //   if (cart !== undefined && cart.length > 0) 
  //     setShowCartExpiry(true);
  // });

  const updateQuantity = async (_id: string, qty: number) => {
    let price: number = 0, discount: number = 0, itemQty: number = 0;

    if (cart !== undefined && cart.length > 0) {
      const cartDetail = cart.find(cartItem => cartItem.orderitems._id === _id);
      const itemDetail = item.find(val => val!._id === cartDetail!.orderitems.item);
      if (cartDetail) {
        itemQty = cartDetail!.orderitems.quantity + qty;

        if (itemQty > 0) {

          if (itemDetail) {
            price = itemDetail!.price * itemQty;
            discount = itemDetail!.discountPercentage > 0 ?  itemDetail!.discountPercentage : (itemDetail!.originalPrice - itemDetail!.price) * 100 / itemDetail!.originalPrice ;
            
            let formData = {
              data: {
                _id,
                quantity: itemQty,
                name: itemDetail!.desc,
                price,
                unitPrice: itemDetail!.originalPrice,
                purchasePrice: itemDetail!.purchasePrice,
                originalPrice: itemDetail!.originalPrice,
                discount,
                isFastDelivery: itemDetail!.isFastDelivery
              }
            }
            updateCart!(formData, token);
            setItemQty(itemQty);
          }
        }
      }
    }
  }

  const removeCart = async (_id: string) => {
    setShowAlert(true);
    setItemId(_id);
  }

  const checkoutClicked = async () => {
    if(Number(cartTotal) < Number(minOrderValue))
      setMinOrderValueError(true); 
    else
      history.push("/tabs/checkout");
  }

  return (
    <IonPage id="shopping-cart">
      <IonContent fullscreen={true}>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass='my-custom-class'
          header={'Are you sure'}
          message={'to remove this item from your cart ?'}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Ok',
              handler: () => {
                deleteCart!(itemId, cart.length, token);
              }
            }
          ]}
        />
        <IonAlert
          isOpen={showCartExpiry}
          onDidDismiss={() => setShowCartExpiry(false)}
          cssClass='my-custom-class'
          header={'Cart Expiry Notice !!'}
          message={`Your cart will be expired and all items added to the cart will be erased in <b> 2 hrs</b>.<br/><br/>Please Checkout and complete your order within 2 hrs.`}
          buttons={[
            {
              text: 'Ok',
              handler: () => {
                deleteCart!("expireCart", 0, token);
              }
            }
          ]}
        />
        <IonAlert
          isOpen={minOrderValueError}
          onDidDismiss={() => setMinOrderValueError(false)}
          cssClass='my-custom-class'
          header={'Order Some More !'}
          message={'Your Minimum Order Value should be Rs.' + minOrderValue.toLocaleString('en-US',{ minimumFractionDigits: 2}) + '.'}
          buttons={[
            {
              text: 'Ok',
            }
          ]}
        />

        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton text="" defaultHref="/tabs/home" />
              <IonLabel>Shopping Cart</IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        {
          (cart.length === 0)
            ? <IonGrid className="empty-cart">
              <IonImg src="assets/img/emptycart.svg" alt="" />
              <p className='no-favourite'>Your cart is empty!</p>
              <IonRow><IonCol></IonCol></IonRow>
              <IonRow>
                <IonCol>
                  <IonButton routerLink="/tabs/homeorintro">Order Now</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>

            :
            <>
              {cart.map(cartItem => {
                const itemDetails = item.find(val => val._id === cartItem.orderitems.item);
                return <IonCard className="shopping-card">
                  <IonGrid>
                    <IonRow>
                      <IonCol size='2' className="image-ion-col">
                        {itemDetails?.images !== undefined
                          ? itemDetails?.images.map(image => (
                            <IonImg src={image.url} alt="item image" />))
                          : <IonImg src={"assets/img/no-image-small.png"} alt="item image" />
                        }
                      </IonCol>

                      <IonCol size='8.5' className="text-ion-col">
                        <IonRow>
                          <IonCol className="text-ion-title" size='8.3'>
                            <IonCardTitle>{itemDetails?.name} </IonCardTitle>
                          </IonCol>
                          <IonCol size='2' >
                            <IonButton className='delete' onClick={() => removeCart(cartItem.orderitems._id)} ><IonIcon icon={trash}></IonIcon></IonButton>
                          </IonCol>
                        </IonRow>
                        <IonCardSubtitle>LKR: {cartItem.orderitems.price.toLocaleString('en-US',{ minimumFractionDigits: 2})} </IonCardSubtitle>
                        <IonCardSubtitle>{itemDetails?.desc} </IonCardSubtitle>
                        <IonButton onClick={() => updateQuantity(cartItem.orderitems._id, -1)} color="white"><IonLabel className="text"><b>-</b></IonLabel></IonButton>
                        <IonButton disabled color="white"><IonLabel className="count">{cartItem.orderitems.quantity}</IonLabel></IonButton>
                        <IonButton onClick={() => updateQuantity(cartItem.orderitems._id, 1)} color="white"><IonLabel className="text"><b>+</b></IonLabel></IonButton>
                      </IonCol>

                      {/* <IonCol size='1.2' className="del-ion-button">
                        <IonButton className="delete" onClick={() => removeCart(cartItem.orderitems._id)} ><IonIcon icon={trash}></IonIcon></IonButton>
                      </IonCol> */}
                    </IonRow>
                  </IonGrid>
                </IonCard>
              })
              }
              <IonGrid>
                <IonRow> <IonCol></IonCol></IonRow>
                <IonRow className="shopping-total">
                  <IonCol size='8'>
                    <p>Number of Items in your Cart : </p>
                    <p>Sub Total (Incl. Dis) LKR:</p>
                  </IonCol>
                  <IonCol size='4'>
                    <p><b>{cart.length}</b></p>
                    <p><b>{cartTotal.toLocaleString('en-US',{ minimumFractionDigits: 2})}</b></p>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton onClick={() => checkoutClicked()}>Checkout</IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonCard  color="warning">
                <IonCardHeader>
                  <IonCardTitle>Cart Expiry Notice !!</IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  Your cart will be expired in <b> 2 hrs</b> and all items in the cart will be erased.<br/>Please Checkout and complete your order within 2 hrs.
                </IonCardContent>
              </IonCard>
            </>
        }
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: (state, ownProps) => ({
    item: state.item.items,
    cart: selectors.getCart(state),
    minOrderValue: selectors.getMinOrderValue(state, ownProps),
    token: state.user.token,
    isLoggedin: state.user.isLoggedin
  }),
  mapDispatchToProps: {
    updateCart,
    deleteCart
  },
  component: ShoppingCart
});
