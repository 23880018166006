import React from 'react';
import {
  IonHeader, IonToolbar,
  IonContent, IonPage,
  IonButtons, IonRow,
  IonCol, IonButton, IonGrid, IonImg
} from '@ionic/react';
import { connect } from '../data/connect';

interface OwnProps { }


const Support: React.FC = () => {

  return <IonPage id="checkout">
    <IonContent>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonGrid className="thank-you">
        <IonImg src="assets/img/thankyou.svg" alt="" />
        <p><b>Thank You!</b> for your order.</p>
        <p>Your order is now being processed.</p>
        <p>We will let you know once the order is ready.</p>
        <IonRow><IonCol></IonCol></IonRow>
        <IonRow>
          <IonCol>
            <IonButton routerLink="/tabs/myOrders">Check your Order Invoice</IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  </IonPage>
};

export default connect<OwnProps, {}>({
  component: Support
})
