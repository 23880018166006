import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonSelectOption, IonSelect, IonButton, IonLabel, IonGrid, IonCol, IonCheckbox, IonRow, IonInput, IonText, IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonList, IonItem, IonAlert, IonToast, IonBackButton } from '@ionic/react';
import { useForm } from 'react-hook-form';
import { signupUser, logoutUser } from '../data/user/user.actions';
import { connect } from '../data/connect';
import { IUser } from '../models/User';
import './Login.scss';

interface OwnProps extends RouteComponentProps { }

interface StateProps {
  user: IUser;
  token: string;
};

interface OwnProps extends RouteComponentProps { }

interface DispatchProps {
  signupUser: typeof signupUser;
  logoutUser: typeof logoutUser;
}

type UpdatePhoneProps = OwnProps & StateProps & DispatchProps;

const UpdatePhone: React.FC<UpdatePhoneProps> = ({ history, signupUser, user, logoutUser, token }) => {

  const [id, setId] = useState('');
  const [copyToWhatsapp, setCopyToWhatsapp] = useState(false);
  const [changePhone, setChangePhone] = useState(false);
  const [phone, setPhone] = useState('');
  const [oldPhone, setOldPhone] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorText, setPhoneErrorText] = useState('');
  const [whatsappError, setWhatsappError] = useState(false);
  const [whatsappErrorText] = useState('');
  const [userCreateError, setUserCreateError] = useState(false);
  const [userCreateErrorText, setUserCreateErrorText] = useState('');
  const [userUpdateMessage, setUserUpdateMessage] = useState(false);
  const [userUpdateMessageText, setUserUpdateMessageText] = useState('');

  const [showToast, setShowToast] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const {register, setValue, handleSubmit, formState: { errors }} = useForm();


  useEffect(() => {
    console.log("-----------------", user)
    if(user){
      setId(user._id);
      setPhone(user.phone);
      setWhatsapp(user.whatsapp != undefined ? user.whatsapp : "");
      setOldPhone(user.phone);
    }
  }, [user, token]);

  const login = async (data:any) => {
    setFormSubmitted(true);
    
    if (!data.phone) {
      setPhoneError(true);
      setPhoneErrorText("Phone is required");
    } else if (data.phone.length > 12 || data.phone.length < 9) {
      setPhoneError(true);
      setPhoneErrorText("Phone is Invalid");
    }
  };

  const clearState = () => {
    setPhone('');
    setWhatsapp('');
  }


  useEffect(() => {
    if(copyToWhatsapp){
      copyWhatsApp(copyToWhatsapp);
    }
  },[phone,copyToWhatsapp])

  useEffect(() => {
    if (formSubmitted  && !phoneError && !whatsappError ) {
      signupUser(user.name.first + " " + user.name.last, user.email, "", phone, "", true, whatsapp, token, createUserCallback);
    }
    setFormSubmitted(false);
  }, [formSubmitted, phoneError, whatsappError]);

  const createUserCallback = useCallback((data: { status: string, message: string }) => {

    if (data.status === "Success") {
      clearState();
      history.push('/verifyPhone', { direction: 'none' });
    } else {
      setUserCreateError(true);
      setUserCreateErrorText(data.message);
      setFormSubmitted(false);
    }
  }, []);

  const copyWhatsApp = (checked: boolean) => {
    setCopyToWhatsapp(checked);
    setWhatsapp('');
    if (checked) {
      let value = '+94';
      if (phone.length === 10) {
        value += phone.substring(1);
      } else if (phone.length > 10) {
        value = '+' + phone;
      } else {
        value += phone;
      }
      setWhatsapp(value);
    }
  }

  return (
    <IonPage id="account-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
            <IonBackButton text="" defaultHref="/tabs/home" />
          </IonButtons>
          <IonTitle>Add Phone</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {
          (<div className="ion-padding-top ion-text-center">
            <form onSubmit={ handleSubmit(login) }>
              <IonList className="input-list">
                
                {/* {changePhone &&  */}
                  <div>
                    <IonItem lines="none">
                      <IonInput {...register("phone")} className="input-text-no-top-margin" placeholder="Phone" name="phone" type="number" value={phone} spellCheck={false} autocapitalize="off"
                        onIonChange={e => {
                          setPhone(e.detail.value!);
                          setPhoneError(false);
                        }} required>
                      </IonInput>
                    </IonItem>
                    {phoneError && <IonText color="danger">
                      <span className="ion-padding-start">
                        {phoneErrorText}
                      </span>
                    </IonText>}

                    <IonItem lines="none" className="iinput-checkbox">
                      <IonContent className="input-link">
                        <IonGrid>
                          <IonRow>
                            <IonCol size='1'><IonCheckbox checked={copyToWhatsapp} onIonChange={e => { copyWhatsApp(e.detail.checked) }} /> </IonCol>
                            <IonCol size='11'><IonItem lines="none" ><IonLabel className="ion-no-padding">Copy Phone Number to WhatsApp</IonLabel></IonItem></IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonContent>
                    </IonItem>

                    <IonItem lines="none">
                      <IonInput {...register("Whatsapp")} className="input-text-no-top-margin" placeholder="Whatsapp" name="whatsapp" type="text" value={whatsapp} spellCheck={false} autocapitalize="off" autocomplete="off"
                        onIonChange={e => {
                          setWhatsapp(e.detail.value!);
                          setWhatsappError(false);
                        }} required>
                      </IonInput>
                    </IonItem>
                    {whatsappError && <IonText color="danger">
                      <span className="ion-padding-start">
                        {whatsappErrorText}
                      </span>
                    </IonText>
                    }
                  </div>
                {/* } */}

                
                
                <IonItem lines="none">
                  <IonButton type="submit" expand="block" className="sign-in-button">Save</IonButton>
                </IonItem>

                {userCreateError && <IonText color="danger">
                  <span className="ion-text-center ion-padding-start">
                    {userCreateErrorText}
                  </span>
                </IonText>}

                {userUpdateMessage && <IonText color="success">
                  <span className="ion-text-center ion-padding-start">
                    {userUpdateMessageText}
                  </span>
                </IonText>}
              </IonList>
            </form>
          </div>)
        }
      </IonContent>
      <IonAlert
        isOpen={showAlert}
        header="Change Username"
        buttons={[
          'Cancel',
          {
            text: 'Ok',
            handler: (data) => {

            }
          }
        ]}
        inputs={[
          {
            type: 'text',
            name: 'username',
            value: "Asa",
            placeholder: 'username'
          }
        ]}
        onDidDismiss={() => setShowAlert(false)}
      />
      <IonToast
        isOpen={showToast}
        duration={3000}
        message="You have successfully created your Account !"
        onDidDismiss={() => setShowToast(false)} />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.user,
    token: state.user.token
  }),
  mapDispatchToProps: {
    signupUser, logoutUser
  },
  component: UpdatePhone
})
