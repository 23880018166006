import React, { useState } from 'react';
import { IonHeader, IonToolbar, IonContent, IonPage, IonButtons, IonMenuButton, IonPopover, IonBackButton } from '@ionic/react';
import AboutPopover from '../components/AboutPopover';
import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import { INotification } from '../models/User';
import './About.scss';


interface StateProps {
  aboutUs: INotification;
};

interface OwnProps { }

interface DispatchProps { }

type AboutProps = OwnProps & StateProps & DispatchProps;


const About: React.FC<AboutProps> = ({aboutUs}) => {

  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent] = useState<MouseEvent>();

  return (
    <IonPage id="about-page">
      <IonContent>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
              <IonBackButton text="" defaultHref="/tabs/home" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <div className="about-header">
          {/* Instead of loading an image each time the select changes, use opacity to transition them */}
          <div className="about-image seattle" style={{ 'opacity':  '1' }}></div>
          {/*<div className="about-image austin" style={{ 'opacity': location === 'austin' ? '1' : undefined }}></div>
          <div className="about-image chicago" style={{ 'opacity': location === 'chicago' ? '1' : undefined }}></div>
          <div className="about-image seattle" style={{ 'opacity': location === 'seattle' ? '1' : undefined }}></div> */}
        </div>
        <div className="about-info">
          <br/>
          <p><b>About Us</b></p>
          <p>{JSON.parse(aboutUs.message)[0].header1}</p>
          <p>{JSON.parse(aboutUs.message)[0].paragraph1}</p><br/>
          <p>{JSON.parse(aboutUs.message)[0].header2}</p>
          <p>{JSON.parse(aboutUs.message)[0].paragraph2}</p>
          <p>{JSON.parse(aboutUs.message)[0].footer}</p>
          
        </div>
      </IonContent>

      <IonPopover
        isOpen={showPopover}
        event={popoverEvent}
        onDidDismiss={() => setShowPopover(false)}
      >
        <AboutPopover dismiss={() => setShowPopover(false)} />
      </IonPopover>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, ownProps) => ({
    aboutUs: selectors.getAboutUs(state, ownProps)
  }),
  component: About
})