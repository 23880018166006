import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonContent, IonPage, IonHeader, IonToolbar, IonButtons, IonButton, useIonViewWillEnter } from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper';
import { setMenuEnabled } from '../data/sessions/sessions.actions';
import { setHasSeenIntroduction } from '../data/user/user.actions';
import { connect } from '../data/connect';
import './Introduction.scss';
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';

interface OwnProps extends RouteComponentProps { };

interface DispatchProps {
  setHasSeenIntroduction: typeof setHasSeenIntroduction;
  setMenuEnabled: typeof setMenuEnabled;
}

interface IntroductionProps extends OwnProps, DispatchProps { };

const Introduction: React.FC<IntroductionProps> = ({ history, setHasSeenIntroduction, setMenuEnabled }) => {
  const [showSkip, setShowSkip] = useState(true);
  let [swiper, setSwiper] = useState<SwiperCore>();

  useIonViewWillEnter(() => {
    setMenuEnabled(false);
  });

  const startApp = async () => {
    await setHasSeenIntroduction(true);
    await setMenuEnabled(true);
    history.push('/tabs/home', { direction: 'none' });
  };

  const handleSlideChangeStart = () => {
    if (!swiper) return;
    setShowSkip(!swiper.isEnd);
  };

  return (
    <IonPage id="introduction-page">
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="end">
            {showSkip && <IonButton color='primary' onClick={startApp}>Skip</IonButton>}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <Swiper onSwiper={setSwiper} onSlideChangeTransitionStart={handleSlideChangeStart}>
          <SwiperSlide>
            <img src="assets/img/intro/intro_1.svg" alt="" className="slide-image" />
            <h2 className="slide-title">
              Find Food You Love
            </h2>
            <p>
              Our job is to filling your tummy with delicious food and fast delivery.
            </p>
            <img src="assets/img/intro/slider_indicator_1.svg" alt="" className="slide-image" />
          </SwiperSlide>

          <SwiperSlide>
            <img src="assets/img/intro/intro_2.svg" alt="" className="slide-image" />
            <h2 className="slide-title">Place Your Order</h2>
            <p>
              Fast food delivery to your home, office wherever you are
            </p>
            <img src="assets/img/intro/slider_indicator_2.svg" alt="" className="slide-image" />
          </SwiperSlide>

          <SwiperSlide>
            <img src="assets/img/intro/delivery.svg" alt="" className="slide-image" />
            <h2 className="slide-title">Same Day Delivery</h2>
            <p>
              Fast food delivery to your home, office wherever you are
            </p> 
            <IonButton onClick={startApp}>
              Get Started
            </IonButton>
          </SwiperSlide>
        </Swiper>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: ({
    setHasSeenIntroduction,
    setMenuEnabled
  }),
  component: Introduction
});
