import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonButton, IonList, IonItem, IonInput, IonText } from '@ionic/react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import { IUser } from '../models/User';
import { setUserPassword } from '../data/user/user.actions';
import './Login.scss';

interface OwnProps extends RouteComponentProps { }

interface StateProps {
  user: IUser;
  token: string;
}

interface DispatchProps {
  setUserPassword: typeof setUserPassword;
}

interface ChangePasswordProps extends OwnProps, StateProps, DispatchProps { }

const ChangePassword: React.FC<ChangePasswordProps> = ({ history, user, setUserPassword, token }) => {

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [passwordErrorText, setPasswordErrorText] = useState('');
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');
  const { register, handleSubmit } = useForm();

  const changePassword = async (data:any) => {
    setPassword(data.password);
    setConfirmPassword(data.confirmPassword);
    setFormSubmitted(true);

    if (!data.password) {
      setPasswordError(true);
      setPasswordErrorText("Password is required");
    } else if (data.password.length < 6) {
      setPasswordError(true);
      setPasswordErrorText("Password length is at least 6 characters");
    } else if (!data.password.match(/\d/) || !data.password.match(/\D/)) {
      setPasswordError(true);
      setPasswordErrorText("Password should contain characters and numbers");
    }

    if (!data.confirmPassword) {
      setConfirmPasswordError(true);
      setConfirmPasswordErrorText("Confirm Password is required");
    }

    if (data.password !== data.confirmPassword) {
      setConfirmPasswordError(true);
      setConfirmPasswordErrorText("Passwords are not matching");
    }

  };

  useEffect(() => {
    if (formSubmitted && !passwordError && !confirmPasswordError) {
      setUserPassword(user._id, password, token, setPassowrdCallback);
    }
    setFormSubmitted(false);
  }, [formSubmitted, passwordError, confirmPasswordError]);

  const setPassowrdCallback = useCallback((data: { status: string }) => {

    if (data.status === "Success") {
      history.push('/login', { direction: 'none' });
    } else {
      // setUserCreateError(true);
      // setUserCreateErrorText(data.message);
      setFormSubmitted(false);
    }
  }, []);

  return (
    <IonPage id="signup-page">
      <IonContent>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <div className="login-title">
          <IonTitle>Change Password</IonTitle>
        </div>

        <form onSubmit={ handleSubmit(changePassword) }>
          <IonList className="input-list">
            <IonItem lines="none">
              <IonInput {...register("password")} className="input-text" placeholder="Enter New Password" name="password" type="password" value={password}
                onIonChange={e => {
                  setPassword(e.detail.value!);
                  setPasswordError(false);
                }}>
              </IonInput>
            </IonItem>

            {passwordError && <IonText color="danger">
              <span className="ion-padding-start">
                {passwordErrorText}
              </span>
            </IonText>}

            <IonItem lines="none">
              <IonInput {...register("confirmPassword")} className="input-text" placeholder="Confirm Password" name="confirmPassword" type="password" value={confirmPassword}
                onIonChange={e => {
                  setConfirmPassword(e.detail.value!);
                  setConfirmPasswordError(false);
                }}>
              </IonInput>
            </IonItem>

            {confirmPasswordError && <IonText color="danger">
              <span className="ion-padding-start">
                {confirmPasswordErrorText}
              </span>
            </IonText>}

            <IonItem lines="none">
              <IonButton type="submit" expand="block" className="sign-in-button">Change Password</IonButton>
            </IonItem>

          </IonList>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setUserPassword
  },
  mapStateToProps: (state, ownProps) => ({
    user: selectors.getUser(state, ownProps),
    token: state.user.token
  }),
  component: ChangePassword
})
