import { getItemData,addToCartData, updateCartData, deleteCartData, addOrderData, addToFavouriteData, getItemPriceData, getPriceChangedItemsData } from '../dataApi';
import { ActionType } from '../../util/types';
import { IItemState } from './item.state';
import React from 'react';
import { ICart, IItem } from '../../models/Item';


export const loadItemData = (token:string) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getItemData(token);
  dispatch(setData(data));
  dispatch(setLoading(false));  
}


export const getItemPrice = (id: string, price: number, discountPercentage:number, token:string, priceCallback:any) => async (dispatch: React.Dispatch<any>) => {
  const data = await getItemPriceData(id,token);
  console.log("change---", price , data.price, discountPercentage , data.discountPercentage)
  if (Number(price) !== Number(data.price) || discountPercentage !== data.discountPercentage)
    priceCallback({status: true, price: Number(data.price)});
}

export const setLoading = (isLoading: boolean) => ({
  type: 'set-item-loading',
  isLoading
} as const);

export const setData = (data: Partial<IItemState>) => ({
  type: 'set-item-data',
  data
} as const);

export const setPriceChangedItemsData = (items: IItem[]) => ({
  type: 'set-price-changed-items-data',
  items
} as const);


export const setCart = (cart: ICart[] /*Partial<ICart[]>*/) => ({
  type: 'set-cart-data',
  cart
} as const);

export const setCurrentMenu = (currentMenu: number) => ({
  type: 'set-item-currentMenu',
  currentMenu
} as const);

export const addCurrentMenu = (currentMenu: number) => async (dispatch: React.Dispatch<any>) => {
  await dispatch(setCurrentMenu(currentMenu))
};

export const getPriceChangedItems = (token:string) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const items = await getPriceChangedItemsData(token);
  dispatch(setPriceChangedItemsData(items));
  dispatch(setLoading(false));  
}

export const addToCart = (data: object, token: string) => async (dispatch: React.Dispatch<any>) => {
  let cart:any = null;
  await Promise.all([
    cart = await addToCartData(data, token),
    dispatch(setCart(cart))
  ])
};

export const updateCart = (data: object, token: string) => async (dispatch: React.Dispatch<any>) => {
  let cart:any = null;
  await Promise.all([
    cart = await updateCartData(data, token),
    dispatch(setCart(cart))
  ]);
};

export const deleteCart = (id: string, count: number, token: string) => async (dispatch: React.Dispatch<any>) => {
  let cart:any = null;
  await Promise.all([
    cart = await deleteCartData(id, count, token),
    dispatch(setCart(cart))
  ]);
};

export const addOrder = (data: object, token: string) => async (dispatch: React.Dispatch<any>) => {
  await addOrderData(data, token); 
  return "Done";
};

export const addToFavourite = (data: object, token: string) => async (dispatch: React.Dispatch<any>) => {
  await addToFavouriteData(data, token);
};

export type ItemActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof setPriceChangedItemsData>
  | ActionType<typeof setCart>
  | ActionType<typeof setCurrentMenu>
  
  