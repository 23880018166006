import React from 'react';
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/react';
import { Route } from 'react-router';
import { home, cart, heart } from 'ionicons/icons';
import { addCurrentMenu} from '../data/items/item.actions';
import { connect } from '.././data/connect';
// import FAQ from './Faq';
import About from './About';
import ShoppingCart from './ShoppingCart';
import Checkout from './Checkout';
import Account from './MyAccount';
import VerifyPhone from './VerifyPhone';
import UpdatePhone from './UpdatePhone';
import TermsAndConditions from './TermsAndConditions';
import ForgotPassword from './ForgotPassword';
import Signup from './Signup';
// import Support from './Support';
import Introduction from './Introduction';
import AddressBook from './AddressBook';
import Address from './Address';
import MyOrders from './MyOrders';
import Item from './Item';
import MyFavourite from './MyFavourite';
import HomeOrIntroduction from '../components/HomeOrIntroduction';
import RedirectToHome from '../components/RedirectToHome';


interface StateProps {
  isAuthenticated: boolean;
}

interface DispatchProps {
  addCurrentMenu: typeof addCurrentMenu;
}

interface MainTabsProps extends StateProps, DispatchProps { }

const MainTabs: React.FC<MainTabsProps> = ({ isAuthenticated , addCurrentMenu}) => {

  return (
    <IonTabs>
      <IonRouterOutlet>
        {/*
          Using the render method prop cuts down the number of renders your components will have due to route changes.
          Use the component prop when your component depends on the RoutercomponentProps passed in automatically.
        */}
        <Route path="/" component={HomeOrIntroduction} exact />
        {/* <Route path="/tabs/homeorintro" component={HomeOrIntroduction} /> */}
        <Route path="/tabs/homeorintro" render={() => {
                return <RedirectToHome
                addCurrentMenu={addCurrentMenu}

                />;
              }}  />
        <Route path="/tabs/home" component={Item} />
        {/* <Route path="/tabs/faq" render={() => <FAQ />} exact={true} /> */}
        <Route path="/tabs/account" component={Account} />
        <Route path="/tabs/shoppingcart" component={ShoppingCart} />
        <Route path="/tabs/myFavourite" component={MyFavourite} />
        <Route path="/tabs/myOrders" component={MyOrders} />
        <Route path="/tabs/checkout" component={Checkout} />
        <Route path="/tabs/verifyPhone" component={VerifyPhone} />
        <Route path="/tabs/UpdatePhone" component={UpdatePhone} />
        <Route path="/tabs/termsAndConditions" component={TermsAndConditions} />
        <Route path="/tabs/forgotpassword" component={ForgotPassword} />
        <Route path="/tabs/signup" component={Signup} />
        {/* <Route path="/tabs/support" component={Support} /> */}
        <Route path="/tabs/introduction" component={Introduction} />
        <Route path="/tabs/addressbook" component={AddressBook} />
        <Route path="/tabs/address" component={Address} />
        {/* <Route path="/tabs/support" component={Support} /> */}
        <Route path="/tabs/about" render={() => <About />} exact={true} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="home" href="/tabs/homeorintro" >
          <IonIcon icon={home} /> 
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton tab="myfavourite" href={isAuthenticated ? "/tabs/myFavourite" : "/login"}>
          <IonIcon icon={heart} />
          <IonLabel>My Favourite</IonLabel>
        </IonTabButton>
        <IonTabButton tab="shoppingcart" href={isAuthenticated ? "/tabs/shoppingcart" : "/login"}>
          <IonIcon icon={cart} />
          <IonLabel>My Cart</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};


export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isLoggedin
  }),
  mapDispatchToProps: { addCurrentMenu },
  component: MainTabs
})
