import React, { useState } from 'react';
import {
  IonCard, IonItem,
  IonLabel, IonCardContent,
  IonList, IonAlert, IonThumbnail
} from '@ionic/react';

import { IAddress, IRegion } from '../models/User';
import * as selectors from '../data/selectors';
import { deleteAddress } from '../data/user/user.actions';
import { connect } from '../data/connect';

interface OwnProps {
  address: IAddress;
};
interface StateProps {
  token: string
  regions: IRegion[];
};

interface DispatchProps { deleteAddress: typeof deleteAddress };
interface AddressItemProps extends OwnProps, StateProps, DispatchProps {

};

const AddressItem: React.FC<AddressItemProps> = ({ address, token, deleteAddress, regions }) => {
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [addressId, setAddressId] = useState('');

  const addToCartClicked = async () => {
    await deleteAddress(addressId, token);
  }

  return (
    <>
      <IonCard className="speaker-card">
        <IonCardContent>
          <IonList lines="none">
            <IonItem detail={false}>
              <img width='20px' height='20px' slot="start" src="assets/img/myaccount/account-circle.svg" alt="avatar" />
              <IonLabel>
                <h3>{address.addresses.contactName}</h3>
              </IonLabel>
              <img width='20px' height='20px' slot="end" src="assets/img/myaccount/delete.svg" alt="avatar" onClick={() => {
                setAddressId(address.addresses._id);
                setShowDeleteAlert(true);
              }} />
            </IonItem>

            <IonItem detail={false}>
              <img width='20px' height='20px' slot="start" src="assets/img/myaccount/envelope.svg" alt="avatar" />

              <IonLabel>
                <h3>{address.addresses.email}</h3>
              </IonLabel>

              <IonItem className="ion-no-padding" detail={false} routerLink={`/editAddress`}>
                <IonThumbnail>
                  <img width='20px' height='20px' slot="end" src="assets/img/myaccount/edit.svg" alt="avatar" />
                </IonThumbnail>
              </IonItem>
            </IonItem>

            <IonItem detail={false}>
              <img width='20px' height='20px' slot="start" src="assets/img/myaccount/pin.svg" alt="avatar" />

              <IonLabel>
                <h3>{address.addresses.streetAddress + ' (Region : ' + regions.find(val => val._id === address.cities.region)?.name + ')'} </h3>
              </IonLabel>

            </IonItem>

            <IonItem detail={false}>
              <img width='20px' height='20px' slot="start" src="assets/img/myaccount/phone-volume.svg" alt="avatar" />

              <IonLabel>
                <h3>{address.addresses.phone}</h3>
              </IonLabel>

              {address.addresses.isPhoneVerified &&
                <img width='20px' height='20px' slot="end" src="assets/img/myaccount/checkmark-circle.svg" alt="avatar" />
              }

            </IonItem>

          </IonList>
        </IonCardContent>
      </IonCard>

      <IonAlert
        isOpen={showDeleteAlert}
        header="Delete Address?"
        buttons={[
          'Cancel',
          {
            text: 'Delete',
            handler: () =>
              addToCartClicked()
          }
        ]}
        onDidDismiss={() => setShowDeleteAlert(false)}
      />
    </>
  );
};

// export default AddressItem;

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    token: selectors.getToken(state, OwnProps),
    regions: selectors.getRegions(state, OwnProps)
  }),
  mapDispatchToProps: { deleteAddress },
  component: AddressItem
});
