/**
 * @file Performing order creation
 * @author J. Nalinda
*/
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonItem, IonGrid, IonRow, IonCol, IonHeader, IonLabel, IonToolbar, IonButtons, IonContent, IonButton, IonBackButton, IonPage, IonCard, IonAlert, IonCheckbox, IonSelect, IonSelectOption, IonText, IonNote, IonInput, IonBadge } from '@ionic/react'
import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import { ICart, IItem } from '../models/Item';
import { IAddress, IDeliveryCharges, IPaymentMethod } from '../models/User';
import { addOrder, addToFavourite, deleteCart } from '../data/items/item.actions';
import './Checkout.scss';
import { loadUserData } from '.././data/user/user.actions';

interface IOwnProps extends RouteComponentProps {
  token: string;
}

interface IStateProps {
  item: IItem[];
  cart: ICart[];
  addresses: IAddress[];
  paymentMethods: IPaymentMethod[];
  deliveryCharges: IDeliveryCharges[];
  minOrderValue: number;
};

interface IDispatchProps {
  addOrder?: typeof addOrder;
  addToFavourite?: typeof addToFavourite;
  loadUserData: typeof loadUserData;
  deleteCart?: typeof deleteCart;
};

type ICheckoutProps = IOwnProps & IStateProps & IDispatchProps;

const options = {
  cssClass: 'custom-popover'
};

const Checkout: React.FC<ICheckoutProps> = ({ item, cart, addresses, paymentMethods, deliveryCharges, minOrderValue, addOrder, addToFavourite, loadUserData, deleteCart, token, history }) => {

  const [cartTotal, setCartTotal] = useState(0);
  const [deliveryChargeAmount, setDeliveryChargeAmount] = useState(0);
  const [thresholdValue, setThresholdValue] = useState(0);
  const [nextDeliveryDate, setNextDeliveryDate] = useState('');
  const [deliveryAddressId, setDeliveryAddressId] = useState('');
  const [paymentMethodId, setpaymentMethodId] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState<IAddress>();
  const [notes, setNotes ] = useState('');
  const [orderPickUp, setOrderPickUp] = useState(false);
  const [favouriteChecked, setFvouriteChecked] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [paymethodError, setPaymethodError] = useState(false);

  useEffect(() => {
    let delieveryAddress: any;
    console.log("++",deliveryAddressId,orderPickUp)
    //setDeliveryChargeAmount(0);
    addresses.length == 0 && setShowAlert(true);
    if (addresses && addresses.length > 0) {
      if (cart !== undefined && cart.length > 0) {
        const total: number = cart.reduce((total, currentValue) => total += currentValue.orderitems.price, 0);
        setCartTotal(total);
      }
      if (deliveryAddressId === '') {
        delieveryAddress = addresses.find(address => address.addresses.isDelivery);
        delieveryAddress && setDeliveryAddressId(delieveryAddress!.addresses._id);
      } else {
        setAddressError(false);
        delieveryAddress = addresses.find(address => address.addresses._id === deliveryAddressId);
        const deliveryCharge = deliveryCharges.find(deliveryCharge => deliveryCharge.region == delieveryAddress!.cities.region && deliveryCharge.thresholdValue > cartTotal && !orderPickUp);
        deliveryCharge ? setDeliveryChargeAmount(deliveryCharge!.deliveryCharges) : setDeliveryChargeAmount(0);
        deliveryCharge ? setThresholdValue(deliveryCharge!.thresholdValue) : setThresholdValue(0);
        const nextDelivery = deliveryCharges.find(deliveryCharge => deliveryCharge.region == delieveryAddress!.cities.region);
        nextDelivery && setNextDeliveryDate(nextDelivery.nextDeliveryDate); 
      }
      setDeliveryAddress(delieveryAddress);
    }
  }, [deliveryAddressId, orderPickUp, addresses]);

  const createOrder = async () => {
    if (!deliveryAddressId) {
      setAddressError(true);
      return;
    }
    if (!paymentMethodId) {
      setPaymethodError(true);
      return;
    }

    let subTotal: number = 0, totalDiscount: number = 0, orderItems: object[] = [], favItems: object[] = [];
    let randomNumber = Math.floor(Math.random() * 10000 + 1);
    let orderId = new Date().toISOString().slice(0, 10) + "-" + randomNumber;
    let isFastDelivery = false ;

    if (cart !== undefined && cart.length > 0 && deliveryAddress != undefined) {
      cart.map(val => {
        subTotal += val.orderitems.price;
        totalDiscount += val.orderitems.discount;
        isFastDelivery = isFastDelivery === true ? isFastDelivery : val.orderitems.isFastDelivery;

        orderItems.push({
          _id: val.orderitems._id,
          item: val.orderitems.item,
          quantity: val.orderitems.quantity,
          name: val.orderitems.name
        })

        favItems.push({
          item: val.orderitems.item,
        })
      });

      let formData = {
        order: {
          orderId,
          billingAddress: deliveryAddress.addresses._id,
          deliveryAddress: deliveryAddress.addresses._id,
          paymentOption: paymentMethodId,
          deliveryCharges: deliveryChargeAmount,
          totalDiscount,
          subTotal,
          orderItems,
          user: deliveryAddress.users._id,
          name: deliveryAddress.addresses.contactName,
          telephone: deliveryAddress.users.phone.toString(),
          whatsapp: deliveryAddress.users.whatsapp,
          address: deliveryAddress.addresses.streetAddress + ', ' + deliveryAddress.cities.name,
          city: deliveryAddress.cities._id,
          region: deliveryAddress.cities.region,
          note: notes,
          isFromMobile: "app", //true,
          isFastDelivery,
          favouriteChecked,
          orderPickUp
        }
      }
      
      let favItemData = {
        data: {
          favItems
        }
      }
      console.log(formData);

      await Promise.all([
        addOrder!(formData, token),
        favouriteChecked ? addToFavourite!(favItemData, token) : null,
        favouriteChecked ? loadUserData(token) : null,
        deleteCart!("createOrder", 1, token),     // This will delete the shopping cart
      ]).then(val => history.push('/ThankYou', { direction: 'none' }));
    }
  }

  return (
    <IonPage id="checkout">
      <IonContent fullscreen={true} >
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass='my-custom-class'
          header={'Something is not right !'}
          message={'You have not created a Delivery Address to complete this order. Please create a Delivery Address here.'}
          buttons={[
            {
              text: 'Ok',
              handler: () => {
                history.push('/address', { direction: 'none' });
              }
            }
          ]}
        />
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton text="" defaultHref="/tabs/shoppingcart" />
              <IonLabel>Checkout</IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonGrid >
          <IonCard className="checkout-card">
            <IonRow>
              <IonCol size='7'>
                <p>Sub Total (Incl. Dis) LKR: </p>
                <p>Delivery Charge LKR:</p>
                <p><b>Total LKR: </b></p><p/>
                <p className='date'>Estimated Delivery Date:</p>

              </IonCol>
              <IonCol>
                <p>{cartTotal.toLocaleString('en-US',{ minimumFractionDigits: 2})}</p>
                <p>{deliveryChargeAmount.toLocaleString('en-US',{ minimumFractionDigits: 2})}</p>
                <p><b>{(cartTotal + deliveryChargeAmount).toLocaleString('en-US',{ minimumFractionDigits: 2})}</b></p>
                <p className='date'><b>{ nextDeliveryDate && new Intl.DateTimeFormat("en-GB", {
                                                                              year: "2-digit",
                                                                              month: "short",
                                                                              day: "2-digit",
                                                                              weekday:"short"
                                                                            }).format(Date.parse(nextDeliveryDate))}</b></p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                  <IonBadge color="primary">Our minimum order value is LKR {minOrderValue + "/-"} . <br></br>A rate of LKR {deliveryChargeAmount + "/-"} will be charged for all deliveries less than LKR {thresholdValue + "/-"} .</IonBadge>
              </IonCol>
            </IonRow>
          </IonCard>
          <IonCard>
            <IonRow>
              <IonCol>
                <p>Delievery Address</p>
                <IonSelect interface="popover" interfaceOptions={options} className="input-select" placeholder="Select Delievery Address" value={deliveryAddressId} onIonChange={e => setDeliveryAddressId(e.detail.value)}>
                  {
                    addresses && addresses.map(address =>
                      <IonSelectOption value={address.addresses._id}>{address.addresses.streetAddress /*+ `, ` +  address.cities.name*/}</IonSelectOption>
                    )}
                </IonSelect>
                {addressError &&
                  <p className="ion-padding-start">
                    <IonText color="danger">
                      * Please select the Delivery Address
                    </IonText>
                  </p>
                }
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <p>Payment Method</p>
                <IonSelect interface="popover" className="input-select" placeholder="Select Payment Method" onIonChange={e => setpaymentMethodId(e.detail.value)}>
                  {/* <IonSelect interfaceOptions="{'cssClass': 'wider-popover'}" className="input-select" interfaceOptions={options} onIonChange={e => setpaymentMethodId(e.detail.value)}> */}
                  {
                    paymentMethods && paymentMethods.map(paymentMethod =>
                      <IonSelectOption value={paymentMethod._id}>{paymentMethod.name}</IonSelectOption>
                    )}
                </IonSelect>
                {paymethodError &&
                  <p className="ion-padding-start">
                    <IonText color="danger">
                      * Please select the Payment Method
                    </IonText>
                  </p>
                }
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <p>Special Notes</p>
                <IonInput className="input-select" placeholder="Enter what you want to specify on your order." name="notes" type="text" value={notes} onIonChange={e => { setNotes(e.detail.value!)}}>

                </IonInput>
              </IonCol>
            </IonRow>
          </IonCard>
          <IonRow><IonCol>
            <IonItem>
              <IonCheckbox checked={favouriteChecked} onIonChange={e => setFvouriteChecked(e.detail.checked)}></IonCheckbox>
              <p>Add these items to my favourites</p>
            </IonItem>
            <IonItem>
              <IonCheckbox onIonChange={e => setOrderPickUp(e.detail.checked)}></IonCheckbox>
              <p>I will pickup from the Gusta office</p>
            </IonItem>

          </IonCol></IonRow>
          <IonRow><IonCol>
            <IonButton onClick={() => createOrder()} >Place Order</IonButton>
          </IonCol></IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default connect<IOwnProps, {}, IDispatchProps>({
  mapStateToProps: (state, ownProps) => ({
    item: state.item.items,
    cart: selectors.getCart(state),
    addresses: selectors.getAddresses(state),
    paymentMethods: selectors.getPaymentMethods(state),
    deliveryCharges: selectors.getDeliveryCharges(state),
    minOrderValue: selectors.getMinOrderValue(state, ownProps),
    token: state.user.token
  }),
  mapDispatchToProps: {
    addOrder,
    addToFavourite,
    loadUserData,
    deleteCart
  },
  component: Checkout
});
