import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonSelectOption, IonSelect, IonButton, IonLabel, IonGrid, IonCol, IonCheckbox, IonRow, IonInput, IonText, IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonList, IonItem, IonAlert, IonToast, IonBackButton } from '@ionic/react';
import { useForm } from 'react-hook-form';
import { saveUser, logoutUser } from '../data/user/user.actions';
import { connect } from '../data/connect';
import { IUser } from '../models/User';
import './Login.scss';

interface OwnProps extends RouteComponentProps { }

interface StateProps {
  user: IUser;
  token: string;
};

interface OwnProps extends RouteComponentProps { }

interface DispatchProps {
  saveUser: typeof saveUser;
  logoutUser: typeof logoutUser;
}

type MyAccountProps = OwnProps & StateProps & DispatchProps;

const MyAccount: React.FC<MyAccountProps> = ({ history, saveUser, user, logoutUser, token }) => {

  const [id, setId] = useState('');
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [nameErrorText, setNameErrorText] = useState('');
  const [gender, setGender] = useState('');
  const [genderError, setGenderError] = useState(false);
  const [genderErrorText, setGenderErrorText] = useState('');
  const [addressId] = useState('');
  const [email, setEmail] = useState('');
  const [copyToWhatsapp, setCopyToWhatsapp] = useState(false);
  const [changePhone, setChangePhone] = useState(false);
  const [phone, setPhone] = useState('');
  const [oldPhone, setOldPhone] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [changePassword, setChangePassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  // const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  // const [emailErrorText, setEmailErrorText] = useState('');
  const [passwordErrorText, setPasswordErrorText] = useState('');
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorText, setPhoneErrorText] = useState('');
  const [whatsappError, setWhatsappError] = useState(false);
  const [whatsappErrorText] = useState('');
  const [userCreateError, setUserCreateError] = useState(false);
  const [userCreateErrorText, setUserCreateErrorText] = useState('');
  const [userUpdateMessage, setUserUpdateMessage] = useState(false);
  const [userUpdateMessageText, setUserUpdateMessageText] = useState('');

  const [showToast, setShowToast] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const {register, setValue, handleSubmit, formState: { errors }} = useForm();


  useEffect(() => {
    console.log("-----------------", user)
    if(user){
      setEmail(user.email);
      setId(user._id);
      setFirstName(user.name.first);
      setLastName(user.name.last);
      setGender(user.gender);
      setPhone(user.phone);
      setWhatsapp(user.whatsapp != undefined ? user.whatsapp : "");
      //setPassword("");
      //setConfirmPassword("");
      setOldPhone(user.phone);
    }
  }, [user]);

  const login = async (data:any) => {
    setFormSubmitted(true);
    
    if (!data.firstname && !firstname) {
      setNameError(true);
      setNameErrorText("First name should be entered");
    }else{
      if(data.firstname){
        setFirstName(data.firstname);
      }
      setNameError(false);
    }

    if (!data.gender && !gender) {
      setGenderError(true);
      setGenderErrorText("Gender should be selected");
    }else{
      if(data.gender){
        setGender(data.gender);
      }
      setGenderError(false);
    }

    if (changePhone) {
      if (!data.phone) {
        setPhoneError(true);
        setPhoneErrorText("Phone is required");
      } else if (data.phone.length > 12 || data.phone.length < 9) {
        setPhoneError(true);
        setPhoneErrorText("Phone is Invalid");
      }
    }

    if (changePassword) {
      if (!data.password) {
        setPasswordError(true);
        setPasswordErrorText("Password is required");
      } else if (data.password.length < 6) {
        setPasswordError(true);
        setPasswordErrorText("Password length is at least 6 characters");
      } else if (!data.password.match(/\d/) || !data.password.match(/\D/)) {
        setPasswordError(true);
        setPasswordErrorText("Password should contain characters and numbers");
      }
      if (data.confirmPassword === '') {
        setConfirmPasswordError(true);
        setConfirmPasswordErrorText("Confirm Password is required");
      }

      if (data.password !== data.confirmPassword) {
        setPasswordError(true);
        setConfirmPasswordError(true);

        setPasswordErrorText("Passwords are not matched");
        setConfirmPasswordErrorText("Passwords are not matched");
      }else{
        setConfirmPasswordError(false);
      }
    }
  };

  useEffect(() => {
    if(copyToWhatsapp){
      copyWhatsApp(copyToWhatsapp);
    }
  },[phone,copyToWhatsapp])

  useEffect(() => {
    // alert("useEffect -> " +  nameError+ "-" + passwordError+ "-" + confirmPasswordError+ "-" +phoneError + "-" +whatsappError + "-" +genderError );
    if (formSubmitted && !nameError && !passwordError && !confirmPasswordError && !phoneError && !whatsappError && !genderError) {
      saveUser(id, email, firstname.trim(), lastname.trim(), password.trim(), phone, oldPhone, gender, whatsapp.trim(), addressId, token, saveUserCallback);
    }
    setFormSubmitted(false);
  }, [formSubmitted, nameError, genderError, passwordError, confirmPasswordError, phoneError, whatsappError]);

  const saveUserCallback = useCallback((data: { status: string, message: string }) => {

    if (data.status === "Success") {
      setUserCreateError(false);
      setUserCreateErrorText("");
      setUserUpdateMessage(true);
      setUserUpdateMessageText(data.message);
      setShowToast(true);
      history.push('/tabs/home', { direction: 'none' });
    } else {
      setUserUpdateMessage(false);
      setUserUpdateMessageText("");
      setUserCreateError(true);
      setUserCreateErrorText(data.message);
      setFormSubmitted(false);
    }
  }, []);

  const copyWhatsApp = (checked: boolean) => {
    setCopyToWhatsapp(checked);
    setWhatsapp('');
    if (checked) {
      let value = '+94';
      if (phone.length === 10) {
        value += phone.substring(1);
      } else if (phone.length > 10) {
        value = '+' + phone;
      } else {
        value += phone;
      }
      setWhatsapp(value);
    }
  }

  return (
    <IonPage id="account-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
            <IonBackButton text="" defaultHref="/tabs/home" />
          </IonButtons>
          <IonTitle>My Account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {
          (<div className="ion-padding-top ion-text-center">
            {/* <img src="https://www.gravatar.com/avatar?d=mm&s=140" alt="avatar" /> */}
            {/* <img src="assets/img/myaccount/profilePicture.svg" alt="avatar" /> */}
            <img src={gender === "Male" ? "assets/img/myaccount/boy.png" : "assets/img/myaccount/girl.png"} alt="avatar" />
            <h2>{email}</h2>
            <form onSubmit={ handleSubmit(login) }>
              <IonList className="input-list">
                <IonItem lines="none">
                  <IonLabel color="secondry">First name</IonLabel>
                </IonItem>

                <IonItem lines="none">
                  <IonInput {...register("firstname")} className="input-text-no-top-margin input-text-no-bottom-margin" placeholder="First name" name="firstname" type="text" value={firstname} spellCheck={false} autocapitalize="off"
                    onIonChange={e => {
                      setFirstName(e.detail.value!);
                      setNameError(false);
                    }} required>
                  </IonInput>
                </IonItem>

                {nameError && <IonText color="danger">
                  <span className="ion-padding-start">
                    {nameErrorText}
                  </span>
                </IonText>}

                <IonItem lines="none">
                  <IonLabel color="secondry" className="account-label">Last name</IonLabel>
                </IonItem>

                <IonItem lines="none">
                  <IonInput {...register("lastname")} className="input-text-no-top-margin input-text-no-bottom-margin" placeholder="Last name" name="lastname" type="text" value={lastname} spellCheck={false} autocapitalize="off"
                    onIonChange={e => { setLastName(e.detail.value!); }} required>
                  </IonInput>
                </IonItem>

                <IonItem lines="none">
                  <IonLabel color="secondry">Gender</IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <IonSelect {...register("gender")}  className="input-select input-text-no-top-margin input-text-no-bottom-margin" value={gender} placeholder="Select M/F"
                    onIonChange={e => {
                      setGender(e.detail.value);
                      setGenderError(false);
                    }}>
                    <IonSelectOption value="Female">Female</IonSelectOption>
                    <IonSelectOption value="Male">Male</IonSelectOption>
                  </IonSelect>
                </IonItem>

                {genderError && <IonText color="danger">
                  <span className="ion-padding-start">
                    {genderErrorText}
                  </span>
                </IonText>}

                {/* <IonItem  lines="none" className="iinput-checkbox"> */}

                <IonItem lines="none">
                  <IonCheckbox {...register("changePhone")}  slot="start" checked={changePhone} onIonChange={e => { setChangePhone(e.detail.checked) }} />
                  <p slot="start">Change Phone Number</p>
                </IonItem>

                {changePhone && /*<IonItem lines="none">
                <IonLabel color="secondry">Phone</IonLabel>
            </IonItem>*/
                  <div>
                    <IonItem lines="none">
                      <IonInput {...register("phone")} className="input-text-no-top-margin" placeholder="Phone" name="phone" type="number" value={phone} spellCheck={false} autocapitalize="off"
                        onIonChange={e => {
                          setPhone(e.detail.value!);
                          setPhoneError(false);
                        }} required>
                      </IonInput>
                    </IonItem>
                    {phoneError && <IonText color="danger">
                      <span className="ion-padding-start">
                        {phoneErrorText}
                      </span>
                    </IonText>}

                    <IonItem lines="none" className="iinput-checkbox">
                      <IonContent className="input-link">
                        <IonGrid>
                          <IonRow>
                            <IonCol size='1'><IonCheckbox checked={copyToWhatsapp} onIonChange={e => { copyWhatsApp(e.detail.checked) }} /> </IonCol>
                            <IonCol size='11'><IonItem lines="none" ><IonLabel className="ion-no-padding">Copy Phone Number to WhatsApp</IonLabel></IonItem></IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonContent>
                    </IonItem>

                    {/*<IonItem lines="none">
                    <IonLabel color="secondry">Whatsapp</IonLabel>
                </IonItem>*/}
                    <IonItem lines="none">
                      <IonInput {...register("Whatsapp")} className="input-text-no-top-margin" placeholder="Whatsapp" name="whatsapp" type="text" value={whatsapp} spellCheck={false} autocapitalize="off" autocomplete="off"
                        onIonChange={e => {
                          setWhatsapp(e.detail.value!);
                          setWhatsappError(false);
                        }} required>
                      </IonInput>
                    </IonItem>
                    {whatsappError && <IonText color="danger">
                      <span className="ion-padding-start">
                        {whatsappErrorText}
                      </span>
                    </IonText>
                    }
                  </div>
                }

                {/* <IonItem  lines="none" className="iinput-checkbox">
              <IonContent className="input-link">
                <IonGrid>
                  <IonRow>
                    <IonCol size='1'><IonCheckbox checked={changePassword} onIonChange={e => { setChangePassword(e.detail.checked)}} /> </IonCol>
                    <IonCol size='11'><IonItem lines="none" ><IonLabel className="ion-no-padding">Change the Password</IonLabel></IonItem></IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonItem>     */}

                <IonItem lines="none">
                  <IonCheckbox {...register("changePassword")} slot="start" checked={changePassword} onIonChange={e => { setChangePassword(e.detail.checked) }} />
                  <p slot="start">Change the Password</p>
                </IonItem>
                {changePassword &&
                  <div>
                    <IonItem lines="none">
                      <IonLabel color="secondry">Password</IonLabel>
                    </IonItem>
                    <IonItem lines="none">
                      <IonInput {...register("password")} className="input-text-no-top-margin" placeholder="Enter Password" name="password" type="password" value={password} autocomplete="off"
                        onIonChange={e => {
                          setPassword(e.detail.value!);
                          setPasswordError(false);
                        }}>
                      </IonInput>
                    </IonItem>

                    {passwordError && <IonText color="danger">
                      <span className="ion-padding-start">
                        {passwordErrorText}
                      </span>
                    </IonText>}

                    <IonItem lines="none">
                      <IonLabel color="secondry">Confirm Password</IonLabel>
                    </IonItem>
                    <IonItem lines="none">
                      <IonInput {...register("confirmPassword")} className="input-text-no-top-margin" placeholder="Confirm Password" name="confirmPassword" type="password" value={confirmPassword}
                        onIonChange={e => {
                          setConfirmPassword(e.detail.value!);
                          setConfirmPasswordError(false);
                        }}>
                      </IonInput>
                    </IonItem>

                    {confirmPasswordError && <IonText color="danger">
                      <span className="ion-padding-start">
                        {confirmPasswordErrorText}
                      </span>
                    </IonText>}
                  </div>
                }
                <IonItem lines="none">
                  <IonButton type="submit" expand="block" className="sign-in-button">Save</IonButton>
                </IonItem>

                {userCreateError && <IonText color="danger">
                  <span className="ion-text-center ion-padding-start">
                    {userCreateErrorText}
                  </span>
                </IonText>}

                {userUpdateMessage && <IonText color="success">
                  <span className="ion-text-center ion-padding-start">
                    {userUpdateMessageText}
                  </span>
                </IonText>}

                {/* <IonItem onClick={() => clicked(user.token)}>Update Picture</IonItem>
              <IonItem onClick={() => setShowAlert(true)}>Change Username</IonItem>
              <IonItem onClick={() => clicked('Change Password')}>Change Password</IonItem>
              <IonItem routerLink="/support" routerDirection="none">Support</IonItem>
              <IonItem routerLink="/logout" routerDirection="none">Logout</IonItem> */}
              </IonList>
            </form>
          </div>)
        }
      </IonContent>
      <IonAlert
        isOpen={showAlert}
        header="Change Username"
        buttons={[
          'Cancel',
          {
            text: 'Ok',
            handler: (data) => {

            }
          }
        ]}
        inputs={[
          {
            type: 'text',
            name: 'username',
            value: "Asa",
            placeholder: 'username'
          }
        ]}
        onDidDismiss={() => setShowAlert(false)}
      />
      <IonToast
        isOpen={showToast}
        duration={3000}
        message="You have successfully updated your Account !"
        onDidDismiss={() => setShowToast(false)} />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.user.user ? state.user.user[0] : null,
    token: state.user.token
  }),
  mapDispatchToProps: {
    saveUser, logoutUser
  },
  component: MyAccount
})
