import React, { useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  IonTitle, IonContent,
  IonPage, IonButton,
  IonList, IonItem,
  IonInput, IonText,
  IonToast, IonAlert
} from '@ionic/react';
import { Controller, useForm } from 'react-hook-form';

import { verifyPhone, sendVerifySMS, setIsLoggedIn } from '../data/user/user.actions';
import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import { IUser } from '../models/User';
import './Login.scss';

interface StateProps {
  user: IUser;
  token: string;
};

interface OwnProps extends RouteComponentProps { }

interface DispatchProps {
  verifyPhone: typeof verifyPhone;
  sendVerifySMS: typeof sendVerifySMS;
  setIsLoggedIn: typeof setIsLoggedIn;
}

type VerifyPhoneProps = OwnProps & StateProps & DispatchProps;

const VerifyPhone: React.FC<VerifyPhoneProps> = ({ history, verifyPhone, sendVerifySMS, user, setIsLoggedIn, token }) => {

  const [otp, setOtp] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otpErrorText, setOtpErrorText] = useState('');
  const [verifyPhoneError, setVerifyPhoneError] = useState(false);
  const [verifyPhoneErrorText, setVerifyPhoneErrorText] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { register, handleSubmit } = useForm();

  const verify = async (data:any) => {
    // e: React.FormEvent
    // e.preventDefault();
    setOtp(data.opt);
    setFormSubmitted(true);
    if (!data.opt || data.opt === '' ) {
      setOtpError(true);
      setOtpErrorText('OTP is required');
    }
    else if (!user.otp) {
      await verifyPhone(data.opt, user.email, user.phone, token, verifyPhoneCallback);
    }
    else {
      if (user.otp.toString() === data.opt) {
        setShowToast(true);
        history.push('/ChangePassword', { direction: 'none' });
      }
      else {
        setOtpError(true);
        setOtpErrorText('OTP is incorrect. Please enter the correct OTO received to your phone.');
      }
    }
  };

  const verifyPhoneCallback = useCallback((data: { status: string, message: string }) => {
    if (data.status === "Success") {
      //setIsLoggedIn(true);
      setOtp('');
      setShowAlert(true);
      setShowToast(true);
    } else {
      setVerifyPhoneError(true);
      setVerifyPhoneErrorText(data.message);
    }
  }, []);

  const sendVerifySMSCallback = useCallback((data: { status: string, message: string }) => {
    if (data.status === "Success") {
      history.push('/tabs/home', { direction: 'none' });
    } else {
      setVerifyPhoneError(true);
      setVerifyPhoneErrorText(data.message);
    }
  }, []);

  const sendVerifySMSAction = async () => {
    await sendVerifySMS(user.phone, user.email, token, sendVerifySMSCallback);
  }

  return (
    <IonPage id="login-page">
      <IonContent>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass='my-custom-class'
          header={'Welcome to Gusta !'}
          message={'You have successfully signed up. Please login to Gusta and fill your account details and delivery address.'}
          buttons={[
            {
              text: 'Ok',
              handler: () => {
                history.push('/login', { direction: 'none' });
              }
            }
          ]}
        />
        <div className="login-title">
          <IonTitle>Verify your phone Number</IonTitle>
        </div>

        <form onSubmit={ handleSubmit(verify) }>
          <IonList className="input-list">
            <IonItem lines="none">
              <IonInput {...register("opt")} className="input-text" placeholder='Enter received SMS code' name="opt" type="text" value={otp} spellCheck={false} autocapitalize="off" onIonChange={e => setOtp(e.detail.value!)} required>
              </IonInput>
            </IonItem>

            {formSubmitted && otpError && <IonText color="danger">
              <p className="ion-padding-start">
                {otpErrorText}
              </p>
            </IonText>}

            <IonItem lines="none">
              <IonButton type="submit" expand="block" className="sign-in-button">Verify</IonButton>
            </IonItem>

            {formSubmitted && verifyPhoneError && <IonText color="danger">
              <span className="ion-text-center ion-padding-start">
                {verifyPhoneErrorText}
              </span>
            </IonText>}

            <IonItem lines="none" button onClick={() => sendVerifySMSAction()} className="ion-text-center ion-padding-top">
              <IonContent>
                <span>Send SMS code again &nbsp;</span>
              </IonContent>
            </IonItem>

          </IonList>
        </form>

      </IonContent>
      <IonToast
        isOpen={showToast}
        duration={3000}
        message="Your OTP is successfully validated !"
        onDidDismiss={() => setShowToast(false)} />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    verifyPhone,
    sendVerifySMS,
    setIsLoggedIn
  },
  mapStateToProps: (state, ownProps) => ({
    user: selectors.getUser(state, ownProps),
    token: state.user.token
  }),
  component: VerifyPhone
})
