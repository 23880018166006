import React, { useEffect } from 'react';
import { IonIcon, IonButton, IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonBackButton } from '@ionic/react';
import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import { loadAddresses } from '../data/user/user.actions';
import { IAddress } from '../models/User';
import AddressItem from '../components/AddressItem';
import { addOutline } from 'ionicons/icons';
import './AddressBook.scss';

interface OwnProps { };

interface StateProps {
  addresses: IAddress[];
  token: string
};

interface DispatchProps { loadAddresses: typeof loadAddresses };

interface AddressBookProps extends OwnProps, StateProps, DispatchProps { };

const AddressBook: React.FC<AddressBookProps> = ({ addresses, loadAddresses, token }) => {
  useEffect(() => {
    loadAddresses(token);
  }, []);

  return (
    <IonPage id="speaker-list">
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton text="" defaultHref="/tabs/home" />
          </IonButtons>
          <IonTitle>Address Book</IonTitle>

          <IonButtons slot="end">
            <IonButton routerLink="/address">
              <IonIcon icon={addOutline}></IonIcon>
            </IonButton>

          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={true}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Address Book</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid fixed>
          <IonRow>

            {addresses.length > 0 && addresses.map(address => (
              <IonCol size="12" size-md="6" key={address.addresses._id}>
                <AddressItem
                  key={address.addresses._id}
                  address={address}
                />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>

  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    addresses: state.user.addresses,
    token: selectors.getToken(state, OwnProps),
  }),
  mapDispatchToProps: { loadAddresses },
  component: AddressBook
});
