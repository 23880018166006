import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonGrid, IonCheckbox, IonHeader, IonToolbar, IonContent, IonPage, IonButtons, IonMenuButton, IonRow, IonCol, IonButton, IonList, IonItem, IonLabel, IonInput, IonText } from '@ionic/react';
import { Controller, useForm } from 'react-hook-form';
import { signupUser } from '../data/user/user.actions';
import { connect } from '../data/connect';
import './Login.scss';

interface OwnProps extends RouteComponentProps { }

interface StateProps {
  token: string;
}

interface DispatchProps {
  signupUser: typeof signupUser;
}

interface LoginProps extends OwnProps, StateProps, DispatchProps { }

const Login: React.FC<LoginProps> = ({ history, signupUser: signupUserAction, token }) => {

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [copyToWhatsapp, setCopyToWhatsapp] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [termsAndConditionsError, setTermsAndConditionsError] = useState(false);
  const [termsAndConditionsErrorText, setTermsAndConditionsErrorText] = useState('');


  const [phone, setPhone] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [nameErrorText, setNameErrorText] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorText, setPasswordErrorText] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorText, setPhoneErrorText] = useState('');
  const [whatsappError, setWhatsappError] = useState(false);
  const [whatsappErrorText, setWhatsappErrorText] = useState('');
  const [userCreateError, setUserCreateError] = useState(false);
  const [userCreateErrorText, setUserCreateErrorText] = useState('');
  const { register, handleSubmit } = useForm();

  const login = async (data:any) => {
    // e: React.FormEvent // parameter
    // e.preventDefault();
    setName(data.name);
    setEmail(data.email);
    setPhone(data.phone);
    setPassword(data.password);
    setConfirmPassword(data.confirmPassword);
    setTermsAndConditions(data.termsAndConditions);

    setFormSubmitted(true);

    if (!data.name) {
      setNameError(true);
      setNameErrorText("Your Name is required");
    }

    if (!data.email) {
      await setEmailError(true);
      setEmailErrorText("Email is required");
      //setFormSubmitted(false);
    } else if (!data.email.includes("@") || !data.email.includes(".")) {
      setEmailError(true);
      setEmailErrorText("Email is invalid");
    }

    if (!data.phone) {
      await setPhoneError(true);
      setPhoneErrorText("Phone Number is required");
    } else if (data.phone.length > 12 || data.phone.length < 9) {
      setPhoneError(true);
      setPhoneErrorText("Phone Number is invalid");
    }

    if (!whatsapp) {
      await setWhatsappError(true);
      setWhatsappErrorText("WhatsApp Number is required");
    } else if (whatsapp.length > 12 || whatsapp.length < 9) {
      setWhatsappError(true);
      setWhatsappErrorText("WhatsApp Number is invalid");
    }

    if (!data.password) {
      setPasswordError(true);
      setPasswordErrorText("Password is required");
    } else if (data.password.length < 6) {
      setPasswordError(true);
      setPasswordErrorText("Password length is at least 6 characters");
    } else if (!data.password.match(/\d/) || !data.password.match(/[a-zA-Z]/) || !data.password.match(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/)) {
      setPasswordError(true);
      setPasswordErrorText("Password should contain at least 1 character, 1 number and 1 special character/symbol");
    }

    if (!data.confirmPassword) {
      setConfirmPasswordError(true);
      setConfirmPasswordErrorText("Confirm Password is required");
    }

    if (data.password !== data.confirmPassword) {
      setConfirmPasswordError(true);
      setConfirmPasswordErrorText("Passwords are not matching");
    }

    if (!data.termsAndConditions) {
      setTermsAndConditionsError(true);
      setTermsAndConditionsErrorText("Accept terms and conditions");
    }
    
    // if(!emailError && !passwordError && !confirmPasswordError && !phoneError && !whatsappError && !termsAndConditionsError) {
    //   await signupUserAction(username,email,password,phone,"",whatsapp, token,createUserCallback);
    // }
  };

  useEffect(() => {
    clearState();
  },[])

  useEffect(() => {
    if(copyToWhatsapp){
      copyWhatsApp(copyToWhatsapp);
    }
  },[phone,copyToWhatsapp])
  
  
  const clearState = () => {
    setEmail('');
    setPhone('');
    setWhatsapp('');
    setPassword('');
    setConfirmPassword('');
  }

  useEffect(() => {
    if (formSubmitted && !nameError && !emailError && !passwordError && !confirmPasswordError && !phoneError && !whatsappError && !termsAndConditionsError) {
      signupUserAction(name, email.toLowerCase(), password, phone, "", false, whatsapp, token, createUserCallback);
    }
    setFormSubmitted(false);
  }, [formSubmitted, nameError, emailError, passwordError, confirmPasswordError, phoneError, whatsappError, termsAndConditionsError]);

  const createUserCallback = useCallback((data: { status: string, message: string }) => {

    if (data.status === "Success") {
      clearState();
      history.push('/verifyPhone', { direction: 'none' });
    } else {
      setUserCreateError(true);
      setUserCreateErrorText(data.message);
      setFormSubmitted(false);
    }
  }, []);

  const copyWhatsApp = (checked: boolean) => {
    setCopyToWhatsapp(checked);
    setWhatsapp('');
    if (checked) {
      let value = '+94';
      if (phone.length === 10) {
        value += phone.substring(1);
      } else if (phone.length > 10) {
        value = '+' + phone;
      } else {
        value += phone;
      }
      setWhatsapp(value);
    }
  }

  return (
    <IonPage id="signup-page">
      <IonContent>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <p className="login-title">Sign Up</p>

        <form noValidate onSubmit={ handleSubmit(login) } autoComplete="off">
          <IonList className="input-list">
            <IonItem lines="none">
              <IonInput {...register("name")} className="input-text" placeholder="Enter Your Name" name="name" type="text" value={name} spellCheck={false}
                onIonChange={e => {
                  setName(e.detail.value!);
                  setNameError(false);
                }}
                required>
              </IonInput>
            </IonItem>

            {nameError && <IonText color="danger">
              <span className="ion-padding-start">
                {nameErrorText}
              </span>
            </IonText>}

            <IonItem lines="none">
              <IonInput {...register("email")} className="input-text" placeholder="Enter Email" name="email" type="text" value={email} spellCheck={false} autocapitalize="off"
                onIonChange={e => {
                  setEmail(e.detail.value!);
                  setEmailError(false);
                }}
                required>
              </IonInput>
            </IonItem>

            {emailError && <IonText color="danger">
              <span className="ion-padding-start">
                {emailErrorText}
              </span>
            </IonText>}

            <IonItem lines="none">
              <IonInput {...register("phone")} className="input-text" placeholder="Phone (Your local mobile number)" name="phone" type="number" value={phone} spellCheck={false} autocapitalize="off" onIonChange={e => {
                setPhone(e.detail.value!);
                setPhoneError(false);
              }}
                required>
              </IonInput>
            </IonItem>

            {phoneError && <IonText color="danger">
              <span className="ion-padding-start">
                {phoneErrorText}
              </span>
            </IonText>}

            <IonItem lines="none" className="input-checkbox">
              <IonContent className="input-link">
                <IonGrid>
                  <IonRow>
                    <IonCol size='1'><IonCheckbox checked={copyToWhatsapp} onIonChange={e => { copyWhatsApp(e.detail.checked) }} /> </IonCol>
                    <IonCol size='11'><IonItem lines="none" ><IonLabel className="ion-no-padding">Copy Phone Number to WhatsApp</IonLabel></IonItem></IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonItem>

            <IonItem lines="none">
              <IonInput {...register("whatsapp")} className="input-text" placeholder="WhatsApp Number (Ex: +94777123123)" name="whatsapp" type="text" value={whatsapp} spellCheck={false} autocapitalize="off"
                onIonChange={e => {
                  setWhatsapp(e.detail.value!);
                  setWhatsappError(false);
                }}
                required>
              </IonInput>
            </IonItem>

            {whatsappError && <IonText color="danger">
              <span className="ion-padding-start">
                {whatsappErrorText}
              </span>
            </IonText>}

            <IonItem lines="none">
              <IonInput{...register("password")}  className="input-text" autocomplete="off" placeholder="Enter Password" name="password" type="password" value={password}
                onIonChange={e => {
                  setPassword(e.detail.value!);
                  setPasswordError(false);
                }}>
              </IonInput>
            </IonItem>

            {passwordError && <IonText color="danger">
              <span className="ion-padding-start">
                {passwordErrorText}
              </span>
            </IonText>}

            <IonItem lines="none">
              <IonInput {...register("confirmPassword")} className="input-text" placeholder="Confirm Password" name="confirmPassword" type="password" value={confirmPassword}
                onIonChange={e => {
                  setConfirmPassword(e.detail.value!);
                  setConfirmPasswordError(false);
                }}>
              </IonInput>
            </IonItem>

            {confirmPasswordError && <IonText color="danger">
              <span className="ion-padding-start">
                {confirmPasswordErrorText}
              </span>
            </IonText>}

            <IonItem lines="none" mode='md'>
              <IonContent className="input-link">
                <IonGrid>
                  <IonRow>
                    <IonCol size='1'><IonCheckbox {...register("termsAndConditions")} checked={termsAndConditions}
                      onIonChange={e => {
                        setTermsAndConditions(e.detail.checked);
                        setTermsAndConditionsError(false);
                      }} /></IonCol>
                    <IonCol size='11'><IonItem routerLink='/termsandconditions' lines="none" > <IonLabel className="ion-no-padding">Terms and Conditions</IonLabel></IonItem></IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonItem>

            {termsAndConditionsError && <IonText color="danger">
              <span className="ion-padding-start">
                {termsAndConditionsErrorText}
              </span>
            </IonText>}

            <IonItem lines="none">
              <IonButton type="submit" expand="block" className="sign-in-button">Sign Up</IonButton>
            </IonItem>

            {/*formSubmitted &&*/ userCreateError && <IonText color="danger">
              <span className="ion-text-center ion-padding-start">
                {userCreateErrorText}
              </span>
            </IonText>}

            <IonItem mode='md' lines="none" routerLink="/login" className="ion-text-center ion-padding-top">
              <IonContent>
                <span>Already have a account? &nbsp;</span>
                <span>Login</span>
              </IonContent>
            </IonItem>

          </IonList>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    signupUser
  },
  mapStateToProps: (state, ownProps) => ({
    token: state.user.token
  }),
  component: Login
})
