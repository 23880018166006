export const ENV = {
  ENDPOINT : "",
  CURRENT_ENV : "production",  

    // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQGNpYW8ubGsiLCJ1c2VySUQiOiI1ZTdlZjVlYmVhNWZlMTc0M2RkYmYwMDIiLCJpYXQiOjE2NDQzNzg5MzAsImV4cCI6MTY0OTU2MjkzMH0.ik_Dj19NF0kri2qwkzcH4E1r2pyJ-jOBlIl8U5sE6bQ"
    // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InJhdmluZHJhQHplcm9wb2ludC5ociIsInVzZXJJRCI6IjVlYWE1N2VjZGM3ODFjNWY1ODI0ZmYzZCIsImlhdCI6MTY0NDA2NTQwMiwiZXhwIjoxNjQ5MjQ5NDAyfQ.cbbIMHyatq61zbz_cx8YssMuM5K1-wiO-RvyabdQMIA"
    // const postData = { headers: { Authorization:  token } };
    // HOST_SECURE : "http://192.168.1.13:4000/mobile/secure/",
    // HOST_UNSECURE : "http://192.168.1.13:4000/mobile/unsecure/"
    // HOST_SECURE : "http://192.168.43.38:4000/mobile/secure/",
    // HOST_UNSECURE : "http://192.168.43.38:4000/mobile/unsecure/"
    //   HOST_SECURE : "https://testapp.ciaolife.info/mobile/secure/",
    //   HOST_UNSECURE : "https://testapp.ciaolife.info/mobile/unsecure/"
     HOST_SECURE : "https://gusta.life/mobile/secure/",
     HOST_UNSECURE : "https://gusta.life/mobile/unsecure/"
    // HOST_SECURE : "https://business.gusta.life/mobile/secure/",
    // HOST_UNSECURE : "https://business.gusta.life/mobile/unsecure/"
    // HOST_SECURE : "http://192.168.8.102:4000/mobile/secure/",
    // HOST_UNSECURE : "http://192.168.8.102:4000/mobile/unsecure/"
    // HOST_SECURE : "http://localhost:4000/mobile/secure/",
    // HOST_UNSECURE : "http://localhost:4000/mobile/unsecure/"
}
