import { getLoginData, getUserData, setIsLoggedInData, setUsernameData, setHasSeenIntroductionData, 
  signupUserData, googleSignupUserData, verifyPhoneData, sendVerifySMSData, loginUserData, sendForgotPasswordOtpData, setUserPasswordData,
  saveUserData, getUserAddressesData, deleteAddressData, addAddressData, getUserOrdersData, getOrderItemsData } from '../dataApi';
import { ActionType } from '../../util/types';
import { UserState,NotificationState } from './user.state';
import { IAddress, IOrder } from '../../models/User';


export const loadUserData = (token: string) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  if (token.length > 0)
  {
    const userData = await getUserData();
    dispatch(setData(userData));
  }
  else {
    const loginData = await getLoginData();
    dispatch(setData(loginData));
  }
  dispatch(setLoading(false));
}

export const setLoading = (isLoading: boolean) => ({
  type: 'set-user-loading',
  isLoading
} as const);

export const setData = (data: Partial<UserState>) => ({
  type: 'set-user-data',
  data
} as const);

export const setNotificationData = (data: Partial<UserState>) => ({
  type: 'set-notification-data',
  data
} as const);

export const logoutUser = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLogoffToken(''));
};

export const setIsLoggedIn = (loggedIn: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setIsLoggedInData(loggedIn);
  return ({
    type: 'set-is-loggedin',
    loggedIn
  } as const)
};

/**
 * Login user
 * @param loggedIn 
 * @param email 
 * @param password 
 * @param isGuest 
 * @returns 
 */
export const loginUser = (loggedIn: boolean, email:string, password:string, token: string, loginCallback:any) => async (dispatch: React.Dispatch<any>) => {
  let data = await loginUserData(email, password, token);
  dispatch(setData(data.user));
  loginCallback(data);
};

/*
* Signup
*/                         
export const signupUser = (name:string, email:string, password:string, phone:string, gender:string, isEmaiverified:boolean, whatsapp:string, token: string, createUserCallback:any) => async (dispatch: React.Dispatch<any>) => {
  const data = await signupUserData(name, email, password, "Normal", phone, gender, false, isEmaiverified, false, false, whatsapp, token);
  
  if(data.status == "Success"){
    const userData = {
      email,
      phone
    }
    dispatch(setData(userData));    
  }
  createUserCallback(data);
};

/*
* Google Signup
*/ 
export const googleLoginUser = (name:string, email:string, password:string, phone:string, gender:string,whatsapp:string, token: string, createUserCallback:any, loginCallback:any) => async (dispatch: React.Dispatch<any>) => {
  let data = await loginUserData(email, password, token);
  if(data.status == "Success"){
    dispatch(setData(data.user));
    loginCallback(data);
  }else{
    let data = await googleSignupUserData(name, email, password, "Normal", phone, gender, false, false, false, false, whatsapp, token);
    dispatch(setData(data.user));
    createUserCallback(data);
  }  
    
};

/*
* Signup
*/
export const saveUser = (id:string, email:string,firstname:string,lastname:string, password:string, phone:string, oldPhone:string, gender:string,whatsapp:string, addressId:string, token: string, saveUserCallback:any) => async (dispatch: React.Dispatch<any>) => {
  const data = await saveUserData(id, firstname,lastname, password, phone, oldPhone, gender, whatsapp, addressId, token);
  
  if(data.status == "Success"){
    // const userData = {
    //     email,
    //     phone,
    //     user: {
    //       first: firstname,
    //       last: lastname
    //     }
    // }
    const userData = await getUserData();
    dispatch(setData(userData));
  }
  saveUserCallback(data);
};

/*
* Add Address
*/
export const addAddress = (contactName:string, email:string, phone:string, streetAddress:string, city:string, isDelivery:boolean, isBilling:boolean, user:string, addAddressCallback:any, token:string) => async (dispatch: React.Dispatch<any>) => {
  const data = await addAddressData(contactName, email, phone, streetAddress, city, isDelivery, isBilling, user, token);
  addAddressCallback(data);
};

/**
 * verify phone
 */
 export const verifyPhone = (otp:string, email:string,phone:string, token: string, verifyUserCallback:any) => async (dispatch: React.Dispatch<any>) => {
  const data = await verifyPhoneData(otp,email,phone, token);
  verifyUserCallback(data);
};

/**
 * send verify sms
 */
 export const sendVerifySMS = (phone:string, email:string, token: string, sendVerifySMSCallback:any) => async (dispatch: React.Dispatch<any>) => {
  const data = await sendVerifySMSData(phone,email, token);
  sendVerifySMSCallback(data);
};

/**
 * send forgot password email
 */
// export const sendForgotPasswordEmail = (email:string, token: string, sendForgotPasswordEmailCallback:any) => async (dispatch: React.Dispatch<any>) => {
//   const data = await sendForgotPasswordEmailData(email, token);
//   sendForgotPasswordEmailCallback(data);
// };

/**
 * send forgot password OTP
 */
export const sendForgotPasswordOtp = (phone:string, token: string, sendForgotPasswordOtpCallback:any) => async (dispatch: React.Dispatch<any>) => {
  const data = await sendForgotPasswordOtpData(phone, token);
  if(data.status == "Success"){
    const userData = {
      _id: data._id,
      otp: data.message,
      phone
    }
    await dispatch(setData(userData));    
  }
  sendForgotPasswordOtpCallback(data);
};

export const setUserPassword = (_id:string, password:string, token: string, setPasswordCallback:any) => async (dispatch: React.Dispatch<any>)  => {
  const data = await setUserPasswordData(_id, password, token);
  setPasswordCallback(data);
}

export const setUsername = (username?: string) => async (dispatch: React.Dispatch<any>) => {
  await setUsernameData(username);
  return ({
    type: 'set-username',
    username
  } as const);
}

export const setLogoffToken = (guestToken: string) => ({
  type: 'set-logoff-token',
  guestToken
} as const);

export const setHasSeenIntroduction = (hasSeenIntroduction: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setHasSeenIntroductionData(hasSeenIntroduction);
  return ({
    type: 'set-has-seen-introduction',
    hasSeenIntroduction
  } as const);
} 

export const setDarkMode = (darkMode: boolean) => ({
  type: 'set-dark-mode',
  darkMode
} as const);

export const setAddresses = (addresses: IAddress[]) => ({
  type: 'set-addresses',
  addresses
} as const);

export const setOrders = (orders: IOrder[]) => ({
  type: 'set-orders',
  orders
} as const);

export const loadAddresses = (token:string) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getUserAddressesData(token);
  dispatch(setAddresses(data.addresses));
  dispatch(setLoading(false));
}

export const loadOrders = (token:string) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getUserOrdersData(token);
  dispatch(setOrders(data.orders));
  dispatch(setLoading(false));
}

// export const loadCities = (token:string) => async (dispatch: React.Dispatch<any>) => {
//   dispatch(setLoading(true));
//   const data = await getCitiesData(token);
//   //const data = await getUserAddressesData(token);
//   // dispatch(setAddresses(data.addresses));
//   dispatch(setLoading(false));
// }

export const deleteAddress = (addressId:string, token:string) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  await deleteAddressData(addressId,token);
  const data = await getUserAddressesData(token);
  dispatch(setAddresses(data.addresses));
  dispatch(setLoading(false));
}

export type UserActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof setIsLoggedIn>
  | ActionType<typeof setUsername>
  | ActionType<typeof setLogoffToken>
  | ActionType<typeof setHasSeenIntroduction>
  | ActionType<typeof setDarkMode>
  | ActionType<typeof setAddresses>
  | ActionType<typeof setOrders>
  
