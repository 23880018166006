import React, { useContext } from 'react';
import { IonRouterContext } from '@ionic/react';

interface RedirectToLoginProps {
  setIsLoggedIn: Function;
  setUsername: Function;
  logoutUser: Function;
}

const RedirectToLogin: React.FC<RedirectToLoginProps> = ({ setIsLoggedIn, setUsername, logoutUser }) => {
  const ionRouterContext = useContext(IonRouterContext);
  // useEffect(() => {
  setIsLoggedIn(false);
  setUsername(undefined);
  logoutUser();
  ionRouterContext.push('/tabs/home')
  // }, [setIsLoggedIn, setUsername, logoutUser, ionRouterContext]);
  return null;
};

export default RedirectToLogin;
