import { ComponentPropsWithoutRef } from "react";
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonToggle } from "@ionic/react";

interface MenuListProps extends ComponentPropsWithoutRef<typeof IonList> {
    title: string;
}

interface MenuItemProps extends ComponentPropsWithoutRef<typeof IonItem> {
    icon: string;
    label: string;
    isChecked: boolean;
    onClick: () => void;
    slot?: string;
}

export function MenuContent({ children, forceOverscroll = false }: ComponentPropsWithoutRef<typeof IonContent>) {
    return (
        <IonContent forceOverscroll={forceOverscroll}>
            {children}
        </IonContent>
    );
}

function MenuList({ title, children, lines = 'none' }: MenuListProps) {
    return (
        <IonList lines={lines}>
            <IonListHeader>{title}</IonListHeader>
            {children}
        </IonList>
    );
}

function MenuItem({ slot = 'start', icon, label, isChecked, onClick }: MenuItemProps) {
    return (
        <IonItem>
            <IonIcon slot={slot} icon={icon} />
            <IonLabel>{label}</IonLabel>
            <IonToggle checked={isChecked} onClick={onClick} />
        </IonItem>
    );
}

MenuList.displayName = 'MenuContent.List';
MenuItem.displayName = 'MenuContent.Item';

MenuContent.List = MenuList;
MenuContent.Item = MenuItem;
