import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './global.scss';

import { connect } from './data/connect';
import { AppContextProvider } from './data/AppContext';
import { loadBasicData } from './data/sessions/sessions.actions';
import { deleteCart, loadItemData } from './data/items/item.actions';
import { setIsLoggedIn, setUsername, loadUserData, logoutUser } from './data/user/user.actions';
import MainTabs from './pages/MainTabs';
import Account from './pages/MyAccount';
import Login from './pages/Login';
import VerifyPhone from './pages/VerifyPhone';
import UpdatePhone from './pages/UpdatePhone';
import TermsAndConditions from './pages/TermsAndConditions';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import Signup from './pages/Signup';
import Introduction from './pages/Introduction';
import AddressBook from './pages/AddressBook';
import Address from './pages/Address';
// import MyOrders from '././pages/MyOrders';
import ThankYou from './pages/ThankYou';
// import { values } from 'lodash';
import HomeOrIntroduction from './components/HomeOrIntroduction';
import RedirectToLogin from './components/RedirectToLogin';
import Menu from './components/Menu';

setupIonicReact();
const App: React.FC = () => {
  return (
    <AppContextProvider>
      <IonicAppConnected />
    </AppContextProvider>
  );
};

interface StateProps {
  darkMode: boolean;
  token: string;
}

interface DispatchProps {
  loadBasicData: typeof loadBasicData;
  loadUserData: typeof loadUserData;
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
  logoutUser: typeof logoutUser;
  loadItemData: typeof loadItemData;
  deleteCart?: typeof deleteCart;
}

interface IonicAppProps extends StateProps, DispatchProps { }

const IonicApp: React.FC<IonicAppProps> = ({ darkMode, token, setIsLoggedIn, setUsername, logoutUser, loadBasicData, loadUserData, loadItemData, deleteCart }) => {

  useEffect(() => {
    loadUserData(token);
    if (token.length > 0) {
      loadUserData(token);
      loadBasicData(token);
      loadItemData(token);
      deleteCart!("expireCart", 0, token);
    }
  }, [loadBasicData, loadItemData, loadUserData, token])

  return (
    token.length === 0 ? (
      <div></div>
    ) : (
      <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              {/*
                  We use IonRoute here to keep the tabs state intact,
                  which makes transitions between tabs and non tab pages smooth
                  */}
              <Route path="/tabs" render={() => <MainTabs />} />
              <Route path="/introduction" component={Introduction} />
              <Route path="/termsAndConditions" component={TermsAndConditions} />
              <Route path="/login" component={Login} />
              <Route path="/account" component={Account} />
              <Route path="/verifyPhone" component={VerifyPhone} />
              <Route path="/updatePhone" component={UpdatePhone} />
              <Route path="/forgotPassword" component={ForgotPassword} />
              <Route path="/changePassword" component={ChangePassword} />
              <Route path="/signup" component={Signup} />
              <Route path="/addressbook" component={AddressBook} />
              <Route path="/address" component={Address} />
              <Route path="/thankYou" component={ThankYou} />
              <Route path="/logout" render={() => {
                return <RedirectToLogin
                  setIsLoggedIn={setIsLoggedIn}
                  setUsername={setUsername}
                  logoutUser={logoutUser}

                />;
              }} />
              {/* <Route path="/tabs/" component={HomeOrIntroduction} exact /> */}
              <Route path="/onlineGusta" component={HomeOrIntroduction} exact />
              <Route path="/" component={HomeOrIntroduction} exact />
              <Route path="/homeorintro" component={HomeOrIntroduction} exact />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    )
  )
}

export default App;

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    token: state.user.token
  }),
  mapDispatchToProps: { loadBasicData, loadUserData, setIsLoggedIn, setUsername, logoutUser, loadItemData, deleteCart },
  component: IonicApp
});
