import React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  IonBackButton, 
  IonHeader, IonToolbar,
  IonContent, IonPage, IonButtons
} from '@ionic/react';

import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import { INotification } from '../models/User';
import './TermsAndConditions.scss';

interface StateProps {
  termsAndConditions: INotification;
};

interface OwnProps { }

interface DispatchProps { }

type TermsAndConditionsProps = OwnProps & StateProps & DispatchProps;

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ termsAndConditions }) => {

  return (
    <IonPage id="faq-page">
      <IonContent>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton text="" defaultHref="./tabs/signup" />
              {/* <IonTitle>Terms And Conditions</IonTitle> */}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <br/><br/>
        <div className="faq">
          <h3 className="ion-padding-top ion-padding-start">{JSON.parse(termsAndConditions.message)[0].header}</h3>

          <p>
            {JSON.parse(termsAndConditions.message)[0].text}
          </p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, ownProps) => ({
    termsAndConditions: selectors.getTermsAndConditions(state, ownProps)
  }),
  component: TermsAndConditions
})
