import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonBackButton, IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonButton, IonList, IonItem, IonInput, IonText } from '@ionic/react';
import { Controller, useForm } from 'react-hook-form';
import { sendForgotPasswordOtp } from '../data/user/user.actions';
import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import { IUser } from '../models/User';
import './Login.scss';

interface StateProps {
  //user: IUser;
  token: string;
};

interface OwnProps extends RouteComponentProps { }

interface DispatchProps {
  sendForgotPasswordOtp: typeof sendForgotPasswordOtp;
}

type ForgotPasswordProps = OwnProps & StateProps & DispatchProps;

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ history, sendForgotPasswordOtp, token }) => {

  const [phone, setPhone] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorText, setPhoneErrorText] = useState('');
  const [sendOtpError, setSendOtpError] = useState(false);
  const [sendOtpErrorText, setSendOtpErrorText] = useState('');

  const { register, handleSubmit } = useForm();


  const send = async (data:any) => {
    setPhone(data.phone);
    setFormSubmitted(true);
    if (!data.phone || data.phone === '') {
      setPhoneError(true);
      setPhoneErrorText("Phone number is required");
    }

    // if(phone) {
    //   await sendForgotPasswordOtp(phone,token,sendForgotCallback);
    // }
  };

  useEffect(() => {
    if (formSubmitted && !phoneError) {
      sendForgotPasswordOtp(phone, token, sendForgotCallback);
    }
    setFormSubmitted(false);
  }, [formSubmitted, phoneError, phone]);

  // const sendEmailCallback = useCallback((data) => { 
  //   if(data.status === "Success"){
  //     history.push('/login', {direction: 'none'});
  //   }else{
  //     setSendEmailError(true);
  //     setSendEmailErrorText(data.message);
  //   }
  // } ,[]);

  const sendForgotCallback = useCallback((data: { status: string, message: string }) => {
    if (data.status === "Success") {
      setPhone('');
      history.push('/verifyPhone', { direction: 'none' });
    } else {
      setSendOtpError(true);
      setSendOtpErrorText(data.message);
      setFormSubmitted(false);
    }
  }, []);

  return (
    <IonPage id="login-page">
      <IonContent>

        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton text="" defaultHref="/login" />
            </IonButtons>
            <IonTitle>Forgot Password</IonTitle>
          </IonToolbar>
        </IonHeader>

        {/* <div className="login-title">
          <IonTitle>Verify your phone Number</IonTitle>
        </div> */}

        <form onSubmit={ handleSubmit(send) }>
          <IonList className="input-list">
            <IonItem lines="none">
              <p color="secondry">Enter your registered local mobile phone number to reset your password.</p>
            </IonItem>

            <IonItem lines="none">
              <IonInput {...register("phone")} className="input-text" placeholder='Local Mobile Number (Ex: 0777123456)' name="phone" type="number" value={phone} spellCheck={false} autocapitalize="off"
              onIonChange={e => setPhone(e.detail.value!)}
                required>
              </IonInput>
            </IonItem>

            {phoneError && <IonText color="danger">
              <p className="ion-padding-start">
                {phoneErrorText}
              </p>
            </IonText>}

            <IonItem lines="none">
              <IonButton type="submit" expand="block" className="send-in-button">Send</IonButton>
            </IonItem>

            {sendOtpError && <IonText color="danger">
              <span className="ion-text-center ion-padding-start">
                {sendOtpErrorText}
              </span>
            </IonText>}
          </IonList>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    sendForgotPasswordOtp
  },
  mapStateToProps: (state, ownProps) => ({
    //user: state.user.user[0] ,//selectors.getUser(state, ownProps),
    token: state.user.token
  }),
  component: ForgotPassword
})
